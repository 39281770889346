module.exports = {
  // комопнент который внутри метрики поиск слов фраз  и в словарях (всплывающее окно)
  word_err: 'Данное слово/словосочетание уже используется',
  phrase_settings: 'Настройка фразы',
  any_order: 'Любой порядок слов',
  dict_err: 'Данный словарь уже используется',
  word_for_search: 'Слова/фразы для поиска.',
  dict: 'Словари',
  save: 'Сохранить',
  reset: 'Обнулить',
  cancel: 'Отменить',
  popup_hints: {
    delete_icon: 'Удалить',
    edit_icon: 'Настройка',
    add_dictionary_from_list: 'Добавить словарь из списка',
    to_dictionary: 'Перейти в словарь',
  },
};
