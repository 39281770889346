import React, { FC, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import ReportRecordsTableBlock from '@app/pages/reports/blocks/report-records-table.block';
import useTranslation from '@app/hooks/use-translation';

import { Button, EasyFilterView } from '@ui';

import { Filter, FilterItem, Range } from '@app/components/ui/easy-filter/types';

import { FilterSearchTable } from '@app/components/filter-search-table';

import { TableColumn } from '@app/components/rubber-table/table.types';
import { TableItem } from '@app/components/table/table.type';
import { Oscilogram } from '@app/components/ui/icons/icons-list';
import { AllStatusProject } from '@app/interfaces/analytics';
import {
  FullTextSearchRequestType,
  FullTextSearchResponseType,
} from '@app/interfaces/full-text-search.type';
import { useGetExtAndUsrModelsQuery } from '@app/store/api/asr_model.api';
import { useGetChatRolesQuery } from '@app/store/api/chat-api/get-chat-roles.api';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';
import {
  useLazyGetFullWordSearchByProjectQuery,
  useLazyGetFullWordSearchByProjectChatsQuery,
} from '@app/store/api/get-full-text-search.api';
import { navigationRoutes } from '@app/utils/navigation-routes';

import { ReportOutletContext } from './report.outlet';

const emptyFilter: Filter & Range = {
  range: {
    type: 'n',
    parameters: [],
  },
  filter: [],
};
const ReportRecordsPage: FC = () => {
  const { t } = useTranslation('pages.reports');
  const { metricList, liveReport, currentTemplate } = useOutletContext<ReportOutletContext>();

  const [localFilter, setLocalFilter] = useState<Filter & Range>(emptyFilter);

  // data for search
  const { t: filterSearchT } = useTranslation('components.filterSearch');

  const [fullWordTextSearchRequestData, setFullWordTextSearchRequestData] = useState<
    Partial<FullTextSearchRequestType>
  >({ offset: 0, limit: 10, project_id: liveReport?.project?.project_id || undefined });
  const [fullWordSearchTable, setFullWordSearchTable] = useState<FullTextSearchResponseType>();
  const [getFullTextTableDataVoice] = useLazyGetFullWordSearchByProjectQuery();
  const [getFullTextTableDataChat] = useLazyGetFullWordSearchByProjectChatsQuery();
  const getFullTextTableData = { chat: getFullTextTableDataChat, voice: getFullTextTableDataVoice };
  const { data: channels } = useGetChannelsQuery();
  const { data: models } = useGetExtAndUsrModelsQuery();
  const { data: chatRoles } = useGetChatRolesQuery(undefined, {
    skip: currentTemplate === 'voice',
  });

  // data for search

  useEffect(() => {
    const filterFromChart = localStorage.getItem('filterFromChart');
    if (!filterFromChart) return;
    const allData = JSON.parse(filterFromChart);
    const series = metricList?.find((metric) => allData.series.metric_id === metric.metric_id);
    const filter: Filter & Range = allData.filter;
    if (
      series?.result_value_type === 'str' ||
      allData.series.aggregate === 'count' ||
      allData.series.aggregate === 'prop_num' ||
      allData.series.aggregate === 'sum'
    ) {
      const filterObject: FilterItem = {
        id: series?.metric_id || '',
        value: '0',
        condition: '!=',
      };
      filter.filter.push(filterObject);
    }
    localStorage.removeItem('filterFromChart');
    setLocalFilter(filter);
  }, [metricList]);

  function handleFilterLiveReportRecords(newFilter: Filter & Range) {
    setLocalFilter(newFilter);
    if (!fullWordSearchTable) return;
    const combinedData = { ...fullWordTextSearchRequestData, filter: newFilter };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }
  function getFilteringKeys() {
    return metricList?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }
  function cancelFilter() {
    setLocalFilter(emptyFilter);
  }
  const getSaveBtnDisabled = () => {
    if (localFilter === emptyFilter) return true;
  };

  const getFooterFilter = (
    <div className="flex">
      <Button
        fill="linked"
        label={t('filter_clear')}
        onClick={() => cancelFilter()}
        disabled={getSaveBtnDisabled()}
      />
    </div>
  );

  // search

  function onSubmitSearch(data) {
    const combinedData = { ...fullWordTextSearchRequestData, ...data };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }

  function handleChangePage(type: 'prev' | 'next') {
    switch (type) {
      case 'next':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;
      case 'prev':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;

      default:
        break;
    }
  }
  const idKeyByTemplate = useMemo(() => ({ chat: 'chat_id', voice: 'record_id' }), []);
  const idKeyByTemplate2 = useMemo(() => ({ chat: 'chat', voice: 'record' }), []);

  const recordPathByTemplate = useMemo(
    () => ({ chat: navigationRoutes.chatProjectRecords, voice: navigationRoutes.projectRecords }),
    [],
  );
  function getFullSearchTable(data: Partial<FullTextSearchRequestType>) {
    if (!data) return;
    getFullTextTableData[currentTemplate || 'voice']({
      ...data,
      asr_model_id: data.asr_model_id === 'all' ? undefined : data.asr_model_id,
      channel: data.channel === 'all' ? undefined : data.channel,
      role: data.role === 'all' ? undefined : data.role,
    })
      .unwrap()
      .then((data) => setFullWordSearchTable(data));
  }
  const tableFullTextSearchColumns: TableColumn[] =
    currentTemplate === 'voice'
      ? [
          { index: 'date', title: filterSearchT('table.date'), maxWidth: 240 },
          { index: 'text', title: filterSearchT('table.text') },
          { index: 'channel', title: filterSearchT('table.channel'), maxWidth: 120 },
          { index: 'asr_model_id', title: filterSearchT('table.model'), maxWidth: 120 },
          { index: 'actions', title: filterSearchT('table.actions'), maxWidth: 90 },
        ]
      : [
          { index: 'date', title: filterSearchT('table.date'), maxWidth: 240 },
          { index: 'text', title: filterSearchT('table.text') },
          { index: 'crole_name', title: filterSearchT('table.crole_name'), maxWidth: 180 },
          { index: 'actions', title: filterSearchT('table.actions'), maxWidth: 90 },
        ];
  const tableFullTextSearchData = (fullWordSearchTable?.results || []).map((tableItem) => ({
    ...tableItem,
    date: currentTemplate === 'voice' ? tableItem?.record?.dt_start : tableItem?.chat?.dt_start,
    text: <div dangerouslySetInnerHTML={{ __html: tableItem.text }} title={tableItem.text} />,
    channel: tableItem?.channel?.name,
    crole_name: tableItem?.crole?.name,
    asr_model_id: models?.find((model) => model.model_id === tableItem.asr_model_id)?.name,
    actions: (
      <div className="flex w-full items-center justify-center">
        <Oscilogram
          hintTitle={filterSearchT('popup_hints.to_record_title')}
          size={22}
          className="text-3color  hover:text-action cursor-pointer transition"
          onClick={() =>
            window.open(
              `/${recordPathByTemplate[currentTemplate]}/${liveReport?.project.project_id}/${
                tableItem[idKeyByTemplate2[currentTemplate]][idKeyByTemplate[currentTemplate]]
              }`,
              '_blank',
            )
          }
        />
      </div>
    ),
  })) as unknown as TableItem[];

  function handleResetSearch() {
    setFullWordSearchTable(undefined);
  }

  const fullTextSearchSelect = [
    {
      index: 'channel',
      width: 130,
      defaultValue: 'all',
      options: [
        { title: 'Все каналы', value: 'all' },
        ...(channels?.map((channel) => ({
          title: channel.name,
          value: channel.settings_channels_id,
        })) || []),
      ],
    },
    {
      index: 'role',
      width: 130,
      defaultValue: 'all',
      options: [
        { title: 'Все роли', value: 'all' },
        ...(chatRoles?.map((role) => ({
          title: role.name || '',
          value: role.crole_id || '',
        })) || []),
      ],
    },
  ];

  const apSearchExceptions = liveReport?.project?.status
    ? liveReport?.project?.status === AllStatusProject.ACTIVE ||
      liveReport?.project?.status === AllStatusProject.PAUSE
    : true;

  // search

  return (
    <div>
      <div>
        <EasyFilterView
          dataIdStartDate="reports-records-date-from"
          dataIdEndDate="reports-records-date-to"
          data={localFilter}
          titleMarkup={t('filter_title')}
          onChangeHandler={handleFilterLiveReportRecords}
          keysForFiltering={getFilteringKeys() || []}
          footerMarkup={getFooterFilter}
          filterWithSearch
          handleSubmitSearchWithFilter={onSubmitSearch}
          handleResetSearch={handleResetSearch}
          selects={
            currentTemplate === 'voice' ? [fullTextSearchSelect[0]] : [fullTextSearchSelect[1]]
          }
          disableSearch={!apSearchExceptions}
        />
      </div>
      <div className="mt-[20px]">
        {fullWordSearchTable && (
          <FilterSearchTable
            tableData={tableFullTextSearchData}
            tableCols={tableFullTextSearchColumns}
            handleChangePage={handleChangePage}
            offset={fullWordTextSearchRequestData?.offset || 0}
            limit={fullWordTextSearchRequestData.limit || 10}
          />
        )}
        <ReportRecordsTableBlock hide={!!fullWordSearchTable} filter={localFilter} />
      </div>
    </div>
  );
};

export default ReportRecordsPage;
