import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import ArrowDownIcon2 from '@app/components/ui/icons/icons-list/arrow-down-2';

import { ArticleItem } from '@app/help-page/blocks/types';

import { menuBarClassName } from './menu-bar.styles';
const preferredOrder = [121733121, 173146125, 412483586, 626130947];

const MenuBar: FC<{ menuList?: ArticleItem[] }> = (props) => {
  const { menuList = [] } = props;
  const [droppedLine, setDroppedLine] = useState<Set<number>>(new Set());
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const location = useLocation();

  const sortedMenuList = useMemo(() => {
    const getIndexById = (id: number) => {
      const index = preferredOrder.indexOf(id);
      return index === -1 ? preferredOrder.length : index;
    };
    return [...menuList].sort((a, b) => getIndexById(a.id) - getIndexById(b.id));
  }, [menuList]);

  const toggleMenu = () => {
    setIsCollapsed((prev) => !prev);
  };

  const contentId = parseInt(location.pathname.replace('/support/', ''), 10);

  const menuListToObject = useMemo(() => {
    return menuList.reduce<Record<number, ArticleItem>>((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [menuList]);

  const findMenuPath = useCallback(
    (targetId: number): number[] | null => {
      const traverse = (items: ArticleItem[], path: number[]): number[] | null => {
        for (const item of items) {
          const currentPath = [...path, item.id];
          if (item.id === targetId) {
            return currentPath;
          }
          if (item.child && item.child.children.length > 0) {
            const childItems = item.child.children.map((child) => menuListToObject[child.id]);
            const result = traverse(childItems, currentPath);
            if (result) {
              return result;
            }
          }
        }
        return null;
      };
      const rootItems = menuList.filter((item) => item.parent === 0);
      return traverse(rootItems, []);
    },
    [menuList, menuListToObject],
  );

  useEffect(() => {
    const pathToContent = findMenuPath(contentId);
    if (pathToContent) {
      setDroppedLine(new Set(pathToContent));
    }
  }, [contentId, findMenuPath]);

  const toggleMenuItem = useCallback((id: number) => {
    setDroppedLine((current) => {
      const newSet = new Set(current);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  }, []);

  const renderMenuList = useCallback(
    (menuArticle: ArticleItem, dropArray: Array<number>, parentIteration: number) => {
      const withChildren = Boolean(menuArticle?.child?.children.length);
      const isOpen = droppedLine.has(menuArticle.id);

      const isActive = `/support/${menuArticle.id}` === location.pathname;

      const handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        toggleMenuItem(menuArticle.id);
      };

      const handleLinkClick = () => {
        if (withChildren && !isOpen) {
          toggleMenuItem(menuArticle.id);
        }
      };

      return (
        <li
          key={menuArticle?.id}
          className={cn(
            'block w-full',
            parentIteration === 0 || droppedLine.has(parentIteration) ? 'visible' : 'hidden',
            'rounded-[3px] transition ease-in-out',
          )}
        >
          <div
            className={cn(
              'w-full mb-[1px]',
              { 'pl-[26px]': !withChildren },
              isActive ? 'bg-bg-4 text-black font-medium rounded-[3px]' : '',
            )}
          >
            <div
              className={cn(
                'truncate text-[16px] flex items-center transition ease-in-out hover:bg-bg-4 hover:text-3color hover:font-medium rounded-[3px] py-[7px]',
              )}
              style={{
                paddingLeft: dropArray.length > 0 ? `${24 * (dropArray.length - 1)}px` : '0px',
              }}
            >
              {withChildren && (
                <button onClick={handleIconClick} className="mr-2 ml-2">
                  <ArrowDownIcon2
                    size={11}
                    className={cn(
                      'transform transition cursor-pointer',
                      isOpen ? 'rotate-0' : 'text-primary -rotate-90',
                    )}
                  />
                </button>
              )}
              <NavLink
                to={`/support/${menuArticle?.id}`}
                className={({ isActive }) =>
                  cn(
                    'flex-1 truncate px-2',
                    isActive ? 'bg-bg-4 text-black font-medium' : 'text-black',
                  )
                }
                onClick={handleLinkClick}
              >
                {menuArticle?.title}
              </NavLink>
            </div>
          </div>
          {withChildren && isOpen && (
            <ul className="pl-[0px]">
              {menuArticle.child.children.map((child) =>
                renderMenuList(
                  menuListToObject[child.id],
                  [...dropArray, child.id],
                  menuArticle.id,
                ),
              )}
            </ul>
          )}
        </li>
      );
    },
    [droppedLine, menuListToObject, toggleMenuItem, location.pathname],
  );

  return (
    <aside className={menuBarClassName({ isCollapsed })}>
      <div className="inline-flex w-full flex-col h-full justify-between border-r-[0.5px] border-basic_app_bg">
        {isCollapsed ? (
          <div className="h-full w-full flex justify-end items-end">
            <button
              className="mb-[20px] mr-[20px] w-[24px] bg-bg-4 p-1 rounded-[5px] rotate-270"
              onClick={toggleMenu}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 9L5 5L1 1" stroke="#2E3842" strokeLinecap="round" />
              </svg>
            </button>
          </div>
        ) : (
          <>
            <ul className="px-[5px] pr-[5px]">
              {sortedMenuList
                .filter((currentMenu) => currentMenu.parent === 0)
                .map((currentMenu) => renderMenuList(currentMenu, [currentMenu.id], 0))}
            </ul>
            <div className="h-full w-full flex justify-end items-end">
              <button
                className="mb-[20px] mr-[20px] bg-bg-4 p-1 rounded-[5px]"
                onClick={toggleMenu}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={cn({ 'rotate-180': !isCollapsed })}
                >
                  <path d="M1 9L5 5L1 1" stroke="#2E3842" strokeLinecap="round" />
                </svg>
              </button>
            </div>
          </>
        )}
      </div>
    </aside>
  );
};

export default MenuBar;
