import React, { FC } from 'react';

import { TableColumn, TableItem } from '../table.types';
type rowsPropsType = {
  row: TableItem;
  truncate: boolean;
  specialPropForSearchTable?: boolean;
  column: TableColumn;
};

const defaultRowHeight = 46;
export const Rows: FC<rowsPropsType> = (props) => {
  const { column, truncate, row, specialPropForSearchTable } = props;
  const truncateCondition = specialPropForSearchTable
    ? 'overflow-y-auto max-h-[108px]'
    : truncate
    ? 'truncate'
    : '';

  return (
    <td
      height={defaultRowHeight}
      className={`shadow-sm last:rounded-r-[10px]
                  first:rounded-l-[10px] bg-white  ${truncateCondition}`}
      key={column.index}
    >
      <div className="flex w-full">
        {column.divider === 'left' && (
          <div>
            <div className=" h-[28px] w-[1px] bg-b_light" />
          </div>
        )}
        <div
          className={`pl-[20px] w-full ${truncateCondition} ${specialPropForSearchTable && 'py-1'}`}
          title={
            typeof row?.[column.index] === 'string' || typeof row?.[column.index] === 'number'
              ? row?.[column.index]?.toString()
              : undefined
          }
        >
          {row?.[column.index]}
        </div>
        {column.divider === 'right' && (
          <div>
            <div className="flex-1 h-[28px] w-[1px] bg-b_light" />
          </div>
        )}
      </div>
    </td>
  );
};
