import React, { FC, JSX } from 'react';
import { Navigate } from 'react-router-dom';
// import { navigationRoutes } from '@app/utils/navigation-routes';
import { usePermissionCheck } from '@app/hooks/use-permission';
type RequiredPermissionPropsType = {
  children: JSX.Element;
  tag: string;
  permissions?: 'display' | 'wtf';
};
const RequiredPermission: FC<RequiredPermissionPropsType> = (props) => {
  const { children, tag, permissions = 'display' } = props;
  const isPermission = usePermissionCheck({ tag, permissions });
  if (isPermission) return <Navigate to={`/`} replace />;
  return children;
};

export default RequiredPermission;
