import cn from 'classnames';

import { OptionsPosition, SelectVariant } from './select.type';

export const selectWrapperClassName = ({
  variant,
  disable,
  error,
  withoutBorder,
  customClassName,
}: {
  variant: SelectVariant;
  disable: boolean;
  error: boolean;
  withoutBorder: boolean;
  customClassName: string;
}) =>
  cn(
    variant === 'small' && 'h-[26px] text-[12px]',
    variant === 'medium' && 'h-[34px] text-[15px]',
    error ? 'border-basic_red' : 'border-b_dark',
    'rounded-[10px]',
    !withoutBorder && 'border-[1px]',
    'bg-white',
    'font-[400]',
    'text-2color',
    'relative',
    'rounded-[10px]',
    'select-none',
    'w-full',
    'cursor-pointer',
    disable && 'opacity-40 cursor-not-allowed',
    customClassName || '',
  );

export const selectOptionsWrapper = ({
  position,
  dropTop,
}: {
  dropTop: boolean;
  position: OptionsPosition;
}) =>
  cn(
    'bg-white',
    'border-[1px]',
    'border-b_dark',
    dropTop ? 'bottom-[36px]' : 'top-[36px]',
    'w-full',
    'max-w-[400px]',
    'z-[99999]',
    'absolute',
    position === 'right' && '-right-1.5 mr-[10px]',
    position === 'left' && '-left-1.5  ml-[10px]',
    'justify-start',
    'rounded-[10px]',
    'inline-flex',
    'flex-col',
    'shadow-medium',
  );

export const optionValueClassName = ({
  variant,
  withoutPadding,
}: {
  variant: SelectVariant;
  withoutPadding: boolean;
}) =>
  cn(
    withoutPadding ? 'ml-[5px]' : variant === 'small' && 'ml-[8px] mr-[13px]',
    withoutPadding ? 'ml-[5px]' : variant === 'medium' && 'mx-[13px]',
    'overflow-hidden',
    'flex',
    'items-center',
    'truncate',
    'text-[15px]',
    'text-2color',
    'font-[400]',
    'leading-[16px]',
  );

export const optionItemClassName = ({
  active,
  disabled,
  nullable = false,
  pictogramsMode,
  withoutPadding,
}: {
  nullable: boolean;
  active: boolean;
  disabled: boolean;
  pictogramsMode: boolean;
  withoutPadding: boolean;
}) =>
  cn(
    nullable && 'hidden',
    disabled ? 'cursor-default' : 'cursor-pointer',
    'flex',
    pictogramsMode ? ' rounded-full' : 'rounded-[10px]',
    'text-[15px]',
    !pictogramsMode ? (withoutPadding ? 'px-[3px]' : 'px-[20px]') : '',
    !pictogramsMode && 'py-[10px]',
    'mb-[4px]',
    'truncate',
    'h-full',
    pictogramsMode ? '' : 'w-[calc(100%-14px)]',
    'transition',
    'items-center',
    active && pictogramsMode && 'bg-action/[.15]',
    active && !pictogramsMode && 'bg-pastel_action',
    !active && !disabled && 'hover:bg-b-light',
    !active && !disabled && pictogramsMode && 'hover:bg-action/[.15]',
    !active && !disabled && !pictogramsMode && 'hover:bg-b-light',
  );
