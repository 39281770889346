import * as React from 'react';

export const useIsOverflow = (
  ref?: HTMLElement | null,
  callback?: (hasOverflow: boolean) => void,
) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  React.useEffect(() => {
    const trigger = () => {
      if (!ref) return;
      const hasOverflow =
        ref?.offsetWidth < ref?.scrollWidth || ref?.offsetHeight < ref?.scrollHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (ref) {
      trigger();
    }
  }, [callback, ref]);

  return { overflow: isOverflow };
};
