import React, { FC, ReactNode } from 'react';

import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { EasyModal } from '@ui';

import { MetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';

import HandleMetricForm from './handle-metric-form';

type HandleMetricModalBlockProps = {
  show: boolean;
  onClose: () => void;
  label: ReactNode;
  currentTemplate: 'chat' | 'voice';
  metricSettings: MetricObjType;
  editModal: boolean;
  metricList?: MetricListArrType[];
  metricTemplateSettings?: MetricTemplatesType | undefined;
  metricTemplate?: boolean;
  createFromTemplate?: boolean;
  copyModal?: boolean;
  page?: string;
};

const HandleMetricModalBlock: FC<HandleMetricModalBlockProps> = (props) => {
  const {
    show,
    onClose,
    label,
    metricSettings,
    metricList,
    currentTemplate,
    editModal,
    metricTemplate,
    metricTemplateSettings,
    copyModal,
    createFromTemplate,
    page,
  } = props;
  return (
    <EasyModal
      show={show}
      label={label}
      customLayer={90}
      isAutoClosable={false}
      withoutFooter
      onClose={onClose}
      variant="mediumW650"
    >
      <div className="h-full w-full z-40 px-[20px]">
        <HandleMetricForm
          metricSettings={metricSettings}
          metricList={metricList || []}
          currentTemplate={currentTemplate}
          onClose={onClose}
          isEdit={editModal}
          metricTemplate={metricTemplate}
          metricTemplateSettings={metricTemplateSettings}
          copyModal={copyModal}
          createFromTemplate={createFromTemplate}
          page={page}
        />
      </div>
    </EasyModal>
  );
};

export default HandleMetricModalBlock;
