import React, { FC, useRef, useState } from 'react';
import classNames from 'classnames';
import useOnClickOutside from '@app/hooks/use-onclick-outside';
import { ChevronDownIcon } from '@icons';
import { TooltipWrapper } from '@app/components/ui';

type PlayBackTypeItems = '0.7x' | '0.8x' | '1x' | '1.2x' | '1.4x';
type PlayBackLiItem = { title: PlayBackTypeItems; value: number };

type PlaybackSpeedSelectProps = {
  onChange: (value: number) => void;
  hint?: string;
};

const PlaybackSpeedSelect: FC<PlaybackSpeedSelectProps> = (props) => {
  const { onChange, hint } = props;
  const [playbackOpen, setPlaybackOpen] = useState<boolean>(false);
  const [currentPlayBack, setCurrentPlayBack] = useState<PlayBackLiItem>({ title: '1x', value: 1 });
  const playBackSpeedRef = useRef(null!);
  const playbackSpeed: Array<PlayBackLiItem> = [
    { title: '0.7x', value: 0.7 },
    { title: '0.8x', value: 0.8 },
    { title: '1x', value: 1 },
    { title: '1.2x', value: 1.2 },
    { title: '1.4x', value: 1.4 },
  ];

  function handleChangeAudioSpeed(playback: PlayBackLiItem) {
    onChange?.(playback.value);
    setCurrentPlayBack(playback);
    setPlaybackOpen(false);
  }

  useOnClickOutside(playBackSpeedRef, () => setPlaybackOpen(false));

  const renderPlaybackSpeedList = playbackSpeed.map((item, index) => {
    return (
      <li
        key={index}
        className={classNames(
          'px-2 text-sm font-bold hover:bg-gray-100 cursor-pointer',
          currentPlayBack.value === item.value && 'bg-gray-100',
        )}
        onClick={() => handleChangeAudioSpeed(item)}
      >
        {item.title}
      </li>
    );
  });

  return (
    <div className="relative">
      <div
        className="inline-flex cursor-pointer items-center text-defaultColor"
        onClick={() => setPlaybackOpen(true)}
      >
        <TooltipWrapper content={hint} id={'record_playing_speed'}>
          <span className="text-sm font-bold mr-[6px]">{currentPlayBack?.title}</span>
        </TooltipWrapper>
        <div>
          <ChevronDownIcon className="text-defaultColor" size={12} />
        </div>
      </div>
      <ul
        ref={playBackSpeedRef}
        className="absolute top-full border-[1px] border-gray-200 bg-white text-center rounded-lg p-4"
      >
        {playbackOpen && renderPlaybackSpeedList}
      </ul>
    </div>
  );
};

export default PlaybackSpeedSelect;
