import React, { FC, JSX } from 'react';
import { useAuthContext } from '@app/utils/auth-provider';
import { Navigate } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';

const RequiredAuth: FC<{ children: JSX.Element }> = (props) => {
  const { children } = props;
  const { isAuth } = useAuthContext();
  if (!isAuth) return <Navigate to={`/${navigationRoutes.authorization}`} replace />;
  return children;
};

export default RequiredAuth;
