import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { ChevronDownV2Icon } from '@app/components/ui/icons/icons-list';
import { OptionItem, OptionsPosition, SelectVariant } from '@app/components/ui/select/select.type';
import useOnClickOutside from '@app/hooks/use-onclick-outside';
import SelectItem from '@app/components/ui/select/select-item';

import { pictogramsData } from '@app/components/metric-table-value-build';

import { IconNameType } from '@app/components/ui/icons/icon-name.type';

import TooltipWrapper from '../tooltip-wrapper';

import Icon from '../icons';

import {
  optionValueClassName,
  selectOptionsWrapper,
  selectWrapperClassName,
} from './select.styles';

type SelectProps = {
  defaultValue?: string | number | string[] | number[];
  isEmpty?: boolean;
  withoutBorder?: boolean;
  size?: number;
  placeholder?: string;
  customClassName?: string;
  title?: string;
  error?: string;
  additionalLabelToEveryValue?: string;
  dropTop?: boolean;
  position?: OptionsPosition;
  variant?: SelectVariant;
  options: Array<OptionItem>;
  onChange?: (value: OptionItem['value']) => void;
  onChangeMultiple?: (value: OptionItem['value'][]) => void;
  dropHeight?: number;
  dropWidth?: number;
  disable?: boolean;
  isMulti?: boolean;
  withoutPadding?: boolean;
  pictogramsMode?: boolean;
  additionalPictogramToValueLabel?: boolean;
  tooltip?: { content: string; place?: 'left' | 'right' | 'top' | 'bottom'; id: string };
};

const Select = (props: SelectProps) => {
  const {
    defaultValue,
    position = 'left',
    dropTop = false,
    isEmpty,
    options,
    withoutPadding = false,
    size,
    title,
    variant = 'medium',
    customClassName,
    placeholder,
    onChange,
    dropHeight = 180,
    dropWidth = 300,
    disable = false,
    tooltip,
    isMulti = false,
    error,
    pictogramsMode,
    additionalLabelToEveryValue,
    onChangeMultiple,
    withoutBorder = false,
  } = props;
  const [isShowOptions, changeIsShowOptions] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<
    OptionItem['value'] | OptionItem['value'][] | undefined
  >(defaultValue || '' || []);
  const optionsRef = useRef<HTMLDivElement>(null);

  const defaultEmptyOption: OptionItem = { title: placeholder || '', value: '' };
  const resizeSelectStyle = size ? { width: `${size}px` } : {};
  const resizeSelectStyle2 = size ? { maxWidth: `${size}px`, overflow: 'hidden' } : {};

  if (title && defaultValue && options[0].title !== title)
    options.unshift({ title, value: defaultValue || defaultValue[0] || '' });

  function findActiveOptionByValue(value: OptionItem['value']) {
    return options.find((option) => option.value === value);
  }

  function handleChangeValue(value: OptionItem['value']) {
    if (isMulti) {
      let newValuesArray;
      if ((activeOption as (string | number)[]).includes(value)) {
        newValuesArray = (activeOption as (string | number)[]).filter((item) => item !== value);
      } else {
        newValuesArray = [...(activeOption as (string | number)[]), value];
      }
      setActiveOption(newValuesArray);
      onChangeMultiple?.(newValuesArray);
    } else {
      setActiveOption(value);
      onChange?.(value);
    }
  }
  function toggleShowOptions() {
    changeIsShowOptions(!isShowOptions);
  }

  useEffect(() => {
    setActiveOption(defaultValue);
  }, [defaultValue, options]);

  useOnClickOutside(optionsRef, () => changeIsShowOptions(false));

  const renderOptions = options.map((option, index) => {
    const activeItem = isMulti
      ? (activeOption as (string | number)[]).includes(option.value)
      : activeOption === option.value;
    return (
      <SelectItem
        withoutPadding={withoutPadding}
        pictogramsMode={pictogramsMode}
        isMulti={isMulti}
        key={index}
        icon={option.icon}
        onChange={handleChangeValue.bind(null, option.value)}
        active={activeItem}
        option={option}
      />
    );
  });
  const renderSelectedValue = () => {
    const activeOptionData = findActiveOptionByValue(activeOption as string | number);

    if (typeof activeOptionData?.title !== 'undefined') {
      return (
        <span
          className={cn(
            (findActiveOptionByValue(activeOption as string | number)?.title === '' ||
              activeOption === '') &&
              'text-b_dark',
          )}
        >
          {pictogramsMode ? (
            <Icon size={24} className="text-action" name={pictogramsData[activeOptionData.title]} />
          ) : (
            activeOptionData.title
          )}
        </span>
      );
    } else return <span className="text-4color">{placeholder}</span>;
  };

  return (
    <div
      ref={optionsRef}
      className={selectWrapperClassName({
        variant,
        disable,
        error: Boolean(error),
        withoutBorder,
        customClassName: customClassName || '',
      })}
      onClick={disable ? undefined : toggleShowOptions}
      style={resizeSelectStyle}
    >
      <div
        style={resizeSelectStyle2}
        className="flex h-full relative  items-center justify-between w-full"
      >
        {tooltip ? (
          <TooltipWrapper truncate place={tooltip?.place} content={tooltip.content} id={tooltip.id}>
            <div className={optionValueClassName({ variant, withoutPadding })}>
              {isMulti && (activeOption as (string | number)[]).length
                ? (activeOption as (string | number)[])?.map?.((option, index) => {
                    return (
                      <span key={index} className="ml-[3px]">
                        {(index === 0 && additionalLabelToEveryValue) || ''}
                        {options.find((item) => item.value === option)?.icon && (
                          <Icon
                            size={30}
                            className="text-action"
                            name={
                              options.find((item) => item.value === option)?.icon as IconNameType
                            }
                          />
                        )}
                        {pictogramsMode ? (
                          <Icon
                            size={30}
                            name={
                              pictogramsData[
                                options.find((item) => item.value === option)?.title || ''
                              ]
                            }
                          />
                        ) : (
                          options.find((item) => item.value === option)?.title
                        )}
                        {index !== (activeOption as (string | number)[]).length - 1 ? ',' : '.'}
                      </span>
                    );
                  })
                : renderSelectedValue()}
            </div>
          </TooltipWrapper>
        ) : (
          <div className={optionValueClassName({ variant, withoutPadding })}>
            {isMulti && (activeOption as (string | number)[]).length
              ? (activeOption as (string | number)[])?.map?.((option, index) => {
                  return (
                    <span
                      className={` ${
                        withoutPadding ? 'ml-[3px]' : 'mr-[5px]'
                      } flex items-center w-full `}
                      key={index}
                    >
                      {(index === 0 && additionalLabelToEveryValue) || ''}
                      {/*{additionalPictogramToValueLabel && (*/}
                      {options.find((item) => item.value === option)?.icon && (
                        <Icon
                          size={30}
                          className="text-action"
                          name={options.find((item) => item.value === option)?.icon as IconNameType}
                        />
                      )}

                      {pictogramsMode ? (
                        <Icon
                          size={30}
                          className="text-action"
                          name={
                            pictogramsData[
                              options.find((item) => item.value === option)?.title || ''
                            ]
                          }
                        />
                      ) : (
                        options.find((item) => item.value === option)?.title
                      )}
                      {index !== (activeOption as (string | number)[]).length - 1 ? ',' : ''}
                    </span>
                  );
                })
              : renderSelectedValue()}
          </div>
        )}
        <div className="w-[24px] text-center items-center flex justify-center">
          <ChevronDownV2Icon
            size={13}
            className={cn('text-3color transition mr-[6px]', isShowOptions && 'rotate-180')}
          />
        </div>
      </div>
      {isShowOptions && (
        <div
          className={selectOptionsWrapper({ dropTop, position })}
          style={dropWidth ? { width: `${dropWidth}px` } : {}}
        >
          <div
            onClick={(e) => isMulti && e.stopPropagation()}
            // ref={!isMulti ? undefined : optionsRef}
            className={cn(
              'select_scrollbar',
              ` max-h-[160px] my-[8px] min-h-[34px] mx-[3px] overflow-hidden overflow-y-auto `,
            )}
            style={{ maxHeight: `${dropHeight}px` }}
          >
            <ul
              className={`${pictogramsMode && 'justify-stretch-stretch flex flex-wrap gap-[3px]'}`}
            >
              {isEmpty && (
                <SelectItem
                  active={activeOption === ''}
                  onChange={handleChangeValue.bind(null, '')}
                  option={defaultEmptyOption}
                />
              )}
              {renderOptions}
            </ul>
          </div>
        </div>
      )}
      {!!error && true && (
        <div className="mt-[1px] absolute text-sm text-red-600 dark:text-red-500">{error}</div>
      )}
    </div>
  );
};

export default Select;
