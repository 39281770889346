import React, { FC, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

import { CircleButton, Modal, PageHeader, PermissionAccessWrapper, Skeleton } from '@ui';
import { TrashIcon } from '@icons';
import RecordMeta from '@app/components/audio-build/record-meta';
import AudioChat from '@app/components/audio-build/audio-chat';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslation from '@app/hooks/use-translation';
import connector, { PropsFromRedux } from '@app/utils/store';
import { useAudioContext } from '@app/components/audio-build/record-provider';
import WaveAudio from '@app/components/audio-build/waveaudio/wave-audio.component';
import { CalculatedRecordMetric, Dialog } from '@app/interfaces/records';

import { userPermissions } from '@app/utils/user-permissions';

import {
  useDeleteChatMutation,
  useGetChatDetailsQuery,
} from '@app/store/api/chat-api/all-chats.api';

import { MetaRecords } from '@app/store/reducers/meta.slice';

import RecordMetric from './record-metric';

const AudioContainer: FC<PropsFromRedux> = (props) => {
  const {
    removeRecordRequest,
    recordsState,
    getFileRecordThunk,
    compositeRecordRequest,
    recordMetaRequest,
    metaState,
  } = props;
  const { recordId, projectId, currentTemplate } = useAudioContext();
  const { compositeRecord, audio, isPending, recordIsPending } = recordsState;
  const { isPending: isPendingMeta, recordMeta } = metaState;
  const [currentMetricByRegionId, setCurrentMetricByRegionId] = useState<number>(0);
  const [activeMetric, changeActiveMetric] = useState<CalculatedRecordMetric[string]>();
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [currentAsrModel, changeAsrModel] = useState<string>();
  const [deleteChat] = useDeleteChatMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const pageFrom = location.pathname.split('/')[1];
  const { t } = useTranslation('components.audio');
  const [activeChatTiming, setActiveChatTiming] = useState<
    { from: number; to: number } | undefined
  >();
  const { data: chatRecord, isLoading: chatLoading } = useGetChatDetailsQuery(
    {
      chat_id: recordId,
      project_id: projectId,
    },
    { skip: currentTemplate === 'voice' },
  );

  const [activeMessageId, setActiveMessageId] = useState<Array<string> | null>(null);
  useEffect(() => {
    if (currentTemplate === 'chat') return;
    recordMetaRequest({ record_id: recordId });
  }, [currentTemplate, recordId, recordMetaRequest]);

  useEffect(() => {
    if (currentTemplate === 'chat') return;
    compositeRecordRequest({ currentAsrModel, projectId, recordId });
  }, [compositeRecordRequest, currentAsrModel, currentTemplate, projectId, recordId]);

  useEffect(() => {
    if (currentTemplate === 'chat') return;
    getFileRecordThunk({ recordId });
  }, [currentTemplate, getFileRecordThunk, projectId, recordId]);

  function removeRecord() {
    if (recordId) {
      if (currentTemplate === 'chat') {
        deleteChat({ items: [recordId] });
      } else {
        removeRecordRequest({ items: [recordId] });
      }
      navigate(window.location.pathname.split('/').splice(0, 2).join('/'));
    }
  }

  function handleUpdateRecord(asrModel: string) {
    changeAsrModel(asrModel);
  }

  async function handleCopyText() {
    // eslint-disable-next-line compat/compat
    await window.navigator.clipboard.writeText(window.location.href);
    toast.success(t('link_copied'));
  }
  function handleChangeActiveRecordMetric(metricId: string | undefined, type: 'once' | 'all') {
    switch (type) {
      case 'once': {
        if (metricId) {
          const currentIds =
            calculatedRecordMetric[currentTemplate || 'voice']?.[metricId]?.settings?.ids || [];
          if (calculatedRecordMetric[currentTemplate || 'voice']?.[metricId] !== activeMetric) {
            changeActiveMetric(calculatedRecordMetric[currentTemplate || 'voice']?.[metricId]);
            setCurrentMetricByRegionId(0);
            currentIds &&
              setActiveMessageId([
                String(
                  calculatedRecordMetric[currentTemplate || 'voice']?.[metricId]?.settings
                    ?.messageId?.[0],
                ),
              ]);
          } else {
            let currentRegion = 0;
            if (currentIds.length - 1 > currentMetricByRegionId) {
              setCurrentMetricByRegionId((prevState) => prevState + 1);
              currentRegion = currentMetricByRegionId + 1;
            } else {
              setCurrentMetricByRegionId(0);
            }
            setActiveMessageId([
              calculatedRecordMetric[currentTemplate || 'voice']?.[metricId]?.settings?.messageId?.[
                currentRegion
              ] || '',
            ]);
          }
        }
        break;
      }
      case 'all': {
        if (
          metricId !== undefined &&
          calculatedRecordMetric[currentTemplate || 'voice']?.[metricId]
        ) {
          changeActiveMetric(calculatedRecordMetric[currentTemplate || 'voice']?.[metricId]);
        } else {
          changeActiveMetric(undefined);
        }
        break;
      }
    }
  }

  const chatMeta = useMemo(() => {
    const result: Partial<MetaRecords> = {};
    for (const key in chatRecord?.chat?.metadata) {
      result[key] = chatRecord?.chat?.metadata[key].reduce((acc, current) => {
        acc[current.alias || current.meta_name] = current.meta_value;
        return acc;
      }, {});
    }
    return result;
  }, [chatRecord?.chat?.metadata]) as MetaRecords;
  const chatDialog: Dialog[] = useMemo(() => {
    return chatRecord?.chatDialog || [];
  }, [chatRecord]);

  const metaOfRecord = {
    chat: chatMeta,
    voice: recordMeta,
  };

  const dialogs = {
    chat: chatDialog,
    voice: compositeRecord?.dialogs,
  };

  const calculatedRecordMetric = {
    chat: chatRecord?.calculatedRecordMetric,
    voice: compositeRecord?.calculatedRecordMetric,
  };

  const known_metrics_cases = {
    chat: chatRecord?.known_metrics_cases_chat,
    voice: compositeRecord?.known_metrics_cases,
  };

  const name = {
    chat: `${chatRecord?.chat?.METAChatID} ${chatRecord?.chat?.METAdatetime}`,
    voice: recordMeta?.record_name,
  };

  const renderMetricAndMetaBlocks = (
    <div
      className={cn(
        `${currentTemplate === 'chat' ? 'w-full basis-[48%]' : 'mt-[12px]'}`,
        isFullScreen && 'order-2 basis-1/2 pr-[10px] items-center flex-row w-full',
      )}
    >
      <div className="">
        {isPendingMeta ? (
          <Skeleton width="100%" height={62} />
        ) : (
          <RecordMeta defaultStatus={!projectId} meta={metaOfRecord[currentTemplate || 'voice']} />
        )}
      </div>
      {projectId &&
        calculatedRecordMetric[currentTemplate || 'voice'] &&
        Object.keys(calculatedRecordMetric[currentTemplate || 'voice'] || []).length > 0 && (
          <div className="my-[12px]">
            {/* TODO: */}
            <RecordMetric
              currentTemplate={currentTemplate}
              metricCases={known_metrics_cases[currentTemplate || 'voice']}
              onChangeActiveMetric={handleChangeActiveRecordMetric}
              projectMetric={
                calculatedRecordMetric[currentTemplate || 'voice'] as CalculatedRecordMetric
              }
            />
          </div>
        )}
    </div>
  );
  const leftSideMenuMarkupByType = {
    chat: renderMetricAndMetaBlocks,
    voice: (
      <div className={cn(isFullScreen ? 'basis-[100%]  flex-1 w-[100%]' : 'basis-[48%] order-1')}>
        {currentTemplate === 'voice' ? (
          !recordIsPending && audio && compositeRecord?.dialog_words ? (
            <div className={cn(isFullScreen ? 'basis-2/2' : 'basis-1/2')}>
              <WaveAudio
                recordName={recordMeta?.record_name}
                wordsTimeList={compositeRecord?.wordsTimeList}
                audio={audio}
                emotionTrack={compositeRecord.emotionTrack}
                activeAndChangeCurrentRegion={[currentMetricByRegionId, setCurrentMetricByRegionId]}
                channelSettings={compositeRecord?.dialogChannels}
                activeMetricWords={activeMetric}
                onChangeActiveMessageId={setActiveMessageId}
                activeMessageId={activeMessageId}
                activeChartTiming={activeChatTiming}
                changeFullScreen={setFullScreen.bind(null, (prev) => !prev)}
                dialogs={compositeRecord?.dialogs}
              />
            </div>
          ) : (
            <Skeleton height={286} width="100%" label={t('skeleton_loading_label')} />
          )
        ) : null}

        {!isFullScreen && renderMetricAndMetaBlocks}
      </div>
    ),
  };
  const headerDescriptor = { chat: t('chat'), voice: t('audio') };

  return (
    <div className="">
      <PageHeader
        label={
          <div className="text-speech_analitics">
            <div className="mb-0">{headerDescriptor[currentTemplate]}</div>
            <div className="text-speech_analitics text-[13px]">
              {isPendingMeta ? (
                <Skeleton height="14px" width="100px" />
              ) : (
                <span
                  className="mb-[2px] pl-[0px] cursor-pointer hover:opacity-80"
                  onClick={handleCopyText}
                >
                  {(name && name[currentTemplate || 'voice']) || '-'}
                </span>
              )}
              <CircleButton
                hintTitle={t('popup_hints.copy_link')}
                hintPlace="top"
                icon="CopyIcon"
                size={15}
                className="text-defaultColor ml-[14px]"
                onClick={handleCopyText}
              />
            </div>
          </div>
        }
      >
        {(pageFrom === 'records' || pageFrom === 'chats') && (
          <PermissionAccessWrapper
            permissions={'action'}
            tag={
              currentTemplate === 'chat'
                ? userPermissions.action.actionDeleteChat
                : userPermissions.action.actionDeleteRecord
            }
          >
            <button
              className="text-basic_red p-[5px] inline-flex items-center"
              onClick={setIsDeleteOpen.bind(null, true)}
            >
              <TrashIcon size={15} className="text-basic_red mr-[5px]" />
              {t('delete')}
            </button>
          </PermissionAccessWrapper>
        )}
      </PageHeader>
      <div className={cn('flex w-full', isFullScreen ? ' flex-wrap' : ' gap-[12px]')}>
        {leftSideMenuMarkupByType[currentTemplate || 'voice']}
        <div
          className={cn(
            'basis-1/2 sticky top-[12px]  h-full]',
            isFullScreen ? 'order-3 mt-[12px]' : ' order-2',
          )}
        >
          {isPending || chatLoading ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <AudioChat
              activeMetric={activeMetric}
              activeMessageId={activeMessageId}
              onChangeActiveChartTiming={setActiveChatTiming}
              transcription={dialogs[currentTemplate || 'voice']}
              currentAsrModel={compositeRecord?.currentAsrModel}
              handleUpdateRecordByAsrModel={handleUpdateRecord}
              audioBoolean={
                currentTemplate === 'chat' ? false : compositeRecord?.recordIsRecognized
              }
              currentTemplate={currentTemplate || 'voice'}
            />
          )}
        </div>
        {isFullScreen && renderMetricAndMetaBlocks}
      </div>
      <Modal
        id="remove_record"
        value={isDeleteOpen}
        size="xs"
        title={t('delete_record')}
        setValue={setIsDeleteOpen}
        onApply={removeRecord}
        variant="removal"
      >
        {t('record_will_deleted')}
      </Modal>
    </div>
  );
};

export default connector(AudioContainer);
