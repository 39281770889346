import React, { useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Button, EasyFilterView } from '@ui';

import useTranslation from '@app/hooks/use-translation';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { AllStatusProject, GroupSharedStatus } from '@app/interfaces/analytics';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useCreateLiveReportMutation } from '@app/store/api/live-reports.api';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { useAppSelector } from '@app/store/store';
import { TableMetricRecords } from '@app/components';
import { useGetChatProjectMetricsListQuery } from '@app/store/api/chat-api/chat-metric.api';
import { usePermissionCheck } from '@app/hooks/use-permission';
import { userPermissions } from '@app/utils/user-permissions';
import { useCreateChatLiveReportMutation } from '@app/store/api/chat-api/live-reports.api';
import { TableColumn } from '@app/components/rubber-table/table.types';
import { TableItem } from '@app/components/table/table.type';
import { Oscilogram } from '@app/components/ui/icons/icons-list';
import {
  FullTextSearchRequestType,
  FullTextSearchResponseType,
} from '@app/interfaces/full-text-search.type';
import { useGetExtAndUsrModelsQuery } from '@app/store/api/asr_model.api';
import { useGetChatRolesQuery } from '@app/store/api/chat-api/get-chat-roles.api';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';
import {
  useLazyGetFullWordSearchByProjectQuery,
  useLazyGetFullWordSearchByProjectChatsQuery,
} from '@app/store/api/get-full-text-search.api';
import { FilterSearchTable } from '@app/components/filter-search-table';

export interface GetFilterParams {
  offset: number | string;
  limit: number;
  filter: Filter & Range;
  sortBy?: string;
  sortDesc?: boolean;
  preset_mode?: boolean;
}
const DEFAULT_RECORDS_LIMIT = 10;
const DEFAULT_RECORDS_OFFSET = 0;
const LastProjectRecordsPage = () => {
  const { t } = useTranslation('pages.recordsAp');
  const navigate = useNavigate();
  const { project, currentTemplate } = useOutletContext<AnalyticPageOutlet>();
  const { userSettings } = useAppSelector((state) => state.userSettings);

  const DEFAULT_FILTER_SETUP: GetFilterParams = {
    offset: DEFAULT_RECORDS_OFFSET,
    limit: userSettings?.tablesLimit?.analyticProject?.records || DEFAULT_RECORDS_LIMIT,
    filter: {
      range: {
        type: 'n',
        parameters: [],
      },
      filter: [],
    },
  };
  const [filterParams, changeFilterParams] = useState<GetFilterParams>(DEFAULT_FILTER_SETUP);

  const { data: metricListChat, isLoading: chatMetricListLoading } =
    useGetChatProjectMetricsListQuery(
      { project_id: project?.project_id || '' },
      { skip: !project?.project_id || currentTemplate === 'voice' },
    );
  const { data: metricListVoice, isLoading: projectMetricLoadingVoice } =
    useGetProjectMetricsListQuery(
      {
        project_id: project?.project_id || '',
      },
      { skip: !project?.project_id || currentTemplate === 'chat' },
    );
  const metricList = {
    chat: metricListChat,
    voice: metricListVoice,
  };
  const reportCanBeEdited = usePermissionCheck({
    tag: userPermissions.action.actionEditLiveReport,
    permissions: 'action',
  });
  const [createVoiceLiveReportMutation] = useCreateLiveReportMutation();
  const [createChatLiveReportMutation] = useCreateChatLiveReportMutation();
  const createLiveReportMutation = {
    chat: createChatLiveReportMutation,
    voice: createVoiceLiveReportMutation,
  };

  // data for search
  const { t: filterSearchT } = useTranslation('components.filterSearch');

  const [fullWordTextSearchRequestData, setFullWordTextSearchRequestData] = useState<
    Partial<FullTextSearchRequestType>
  >({ offset: 0, limit: 10, project_id: project?.project_id || undefined });
  const [fullWordSearchTable, setFullWordSearchTable] = useState<FullTextSearchResponseType>();
  const [getFullTextTableDataVoice] = useLazyGetFullWordSearchByProjectQuery();
  const [getFullTextTableDataChat] = useLazyGetFullWordSearchByProjectChatsQuery();
  const getFullTextTableData = { chat: getFullTextTableDataChat, voice: getFullTextTableDataVoice };
  const { data: channels } = useGetChannelsQuery();
  const { data: models } = useGetExtAndUsrModelsQuery();
  const { data: chatRoles } = useGetChatRolesQuery(undefined, {
    skip: currentTemplate === 'voice',
  });

  // data for search

  useEffect(() => {
    changeFilterParams((prev) => ({
      ...prev,
      limit: userSettings?.tablesLimit?.analyticProject?.records || 10,
    }));
  }, [userSettings?.tablesLimit?.analyticProject?.records]);
  // handlers
  function getFilteringKeys() {
    return metricList[currentTemplate || 'voice']?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }
  function cancelFilter() {
    changeFilterParams((prev) => ({
      ...prev,
      filter: DEFAULT_FILTER_SETUP.filter,
    }));
  }
  const getSaveBtnDisabled = () => {
    if (
      filterParams.filter.filter.length === 0 &&
      filterParams.filter.range.parameters.length === 0
    )
      return true;
    if (project?.status !== AllStatusProject.CREATED && project?.status !== AllStatusProject.PAUSE)
      return true;
    return !project?.owner && project?.shared === GroupSharedStatus.VIEW;
  };
  function handleFilterChange(newFilter: Filter & Range) {
    changeFilterParams((prev) => ({ ...prev, filter: newFilter }));
    if (!fullWordSearchTable) return;
    const combinedData = { ...fullWordTextSearchRequestData, filter: newFilter };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }
  function createLiveReport() {
    if (!project) return;
    const result = {
      name: `${project?.name || ''}`,
      description: project?.description || '',
      filter: filterParams.filter,
      project_id: project.project_id || '',
    };
    createLiveReportMutation[currentTemplate || 'voice'](result)
      .unwrap()
      .then((data) => {
        const pashEnter = {
          chat: navigationRoutes.chatReports,
          voice: navigationRoutes.reports,
        };
        navigate(
          `/${pashEnter[currentTemplate || 'voice']}/${data.live_report_id}/${
            navigationRoutes.settings
          }`,
          {
            state: navigationRoutes.reports,
          },
        );
      });
  }
  // jsx markup
  const getTitleFilter = <h2 className="text-1color font-bold">{t('filter.title')}</h2>;
  const getFooterFilter = (
    <div className="flex">
      <Button
        label={
          process.env.REACT_APP_THEME === 'rtk'
            ? t('filter.buttons.create_report_rtk')
            : t('filter.buttons.create_report')
        }
        disabled={(() => {
          if (!reportCanBeEdited) {
            return !project?.owner && project?.shared === GroupSharedStatus.VIEW;
          } else return true;
        })()}
        onClick={() => createLiveReport()}
      />
      <Button
        fill="linked"
        label={t('filter.buttons.cancel')}
        onClick={() => cancelFilter()}
        disabled={getSaveBtnDisabled()}
      />
    </div>
  );

  // search

  function onSubmitSearch(data) {
    const combinedData = { ...fullWordTextSearchRequestData, ...data };
    setFullWordTextSearchRequestData(combinedData);
    getFullSearchTable(combinedData);
  }

  function handleChangePage(type: 'prev' | 'next') {
    switch (type) {
      case 'next':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) +
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;
      case 'prev':
        setFullWordTextSearchRequestData({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        getFullSearchTable({
          ...fullWordTextSearchRequestData,
          offset:
            (fullWordTextSearchRequestData.offset || 0) -
            (fullWordTextSearchRequestData.limit || 10),
        });
        break;

      default:
        break;
    }
  }
  const idKeyByTemplate = useMemo(() => ({ chat: 'chat_id', voice: 'record_id' }), []);
  const idKeyByTemplate2 = useMemo(() => ({ chat: 'chat', voice: 'record' }), []);
  const recordPathByTemplate = useMemo(
    () => ({ chat: navigationRoutes.chatProjectRecords, voice: navigationRoutes.projectRecords }),
    [],
  );
  function getFullSearchTable(data: Partial<FullTextSearchRequestType>) {
    if (!data) return;
    getFullTextTableData[currentTemplate || 'voice']({
      ...data,
      asr_model_id: data.asr_model_id === 'all' ? undefined : data.asr_model_id,
      channel: data.channel === 'all' ? undefined : data.channel,
      role: data.role === 'all' ? undefined : data.role,
    })
      .unwrap()
      .then((data) => setFullWordSearchTable(data));
  }
  const tableFullTextSearchColumns: TableColumn[] =
    currentTemplate === 'voice'
      ? [
          { index: 'date', title: filterSearchT('table.date'), maxWidth: 240 },
          { index: 'text', title: filterSearchT('table.text') },
          { index: 'channel', title: filterSearchT('table.channel'), maxWidth: 120 },
          { index: 'asr_model_id', title: filterSearchT('table.model'), maxWidth: 120 },
          { index: 'actions', title: filterSearchT('table.actions'), maxWidth: 90 },
        ]
      : [
          { index: 'date', title: filterSearchT('table.date'), maxWidth: 240 },
          { index: 'text', title: filterSearchT('table.text') },
          { index: 'crole_name', title: filterSearchT('table.crole_name'), maxWidth: 180 },
          { index: 'actions', title: filterSearchT('table.actions'), maxWidth: 90 },
        ];
  const tableFullTextSearchData = (fullWordSearchTable?.results || []).map((tableItem) => ({
    ...tableItem,
    date: currentTemplate === 'voice' ? tableItem?.record?.dt_start : tableItem?.chat?.dt_start,
    text: <div dangerouslySetInnerHTML={{ __html: tableItem.text }} title={tableItem.text} />,
    channel: tableItem?.channel?.name,
    crole_name: tableItem?.crole?.name,
    asr_model_id: models?.find((model) => model.model_id === tableItem.asr_model_id)?.name,
    actions: (
      <div className="flex w-full items-center justify-center">
        <Oscilogram
          hintTitle={filterSearchT('popup_hints.to_record_title')}
          size={22}
          className="text-3color  hover:text-action cursor-pointer transition"
          onClick={() =>
            window.open(
              `/${recordPathByTemplate[currentTemplate]}/${project?.project_id}/${
                tableItem[idKeyByTemplate2[currentTemplate]][idKeyByTemplate[currentTemplate]]
              }`,
              '_blank',
            )
          }
        />
      </div>
    ),
  })) as unknown as TableItem[];

  function handleResetSearch() {
    setFullWordSearchTable(undefined);
  }

  const fullTextSearchSelect = [
    {
      index: 'channel',
      width: 130,
      defaultValue: 'all',
      options: [
        { title: 'Все каналы', value: 'all' },
        ...(channels?.map((channel) => ({
          title: channel.name,
          value: channel.settings_channels_id,
        })) || []),
      ],
    },
    {
      index: 'role',
      width: 130,
      defaultValue: 'all',
      options: [
        { title: 'Все роли', value: 'all' },
        ...(chatRoles?.map((role) => ({
          title: role.name || '',
          value: role.crole_id || '',
        })) || []),
      ],
    },
  ];

  const apSearchExceptions =
    project?.status === AllStatusProject.ACTIVE || project?.status === AllStatusProject.PAUSE;

  // search

  return (
    <div>
      <div>
        <EasyFilterView
          dataIdStartDate="analytic-project-records-date-from"
          dataIdEndDate="analytic-project-records-date-to"
          data={filterParams.filter}
          isLoading={chatMetricListLoading || projectMetricLoadingVoice}
          titleMarkup={getTitleFilter}
          footerMarkup={getFooterFilter}
          onChangeHandler={handleFilterChange}
          keysForFiltering={getFilteringKeys() || []}
          disabledChangeButton={!project?.project_id}
          filterWithSearch
          handleSubmitSearchWithFilter={onSubmitSearch}
          handleResetSearch={handleResetSearch}
          selects={
            currentTemplate === 'voice' ? [fullTextSearchSelect[0]] : [fullTextSearchSelect[1]]
          }
          disableSearch={!apSearchExceptions}
        />
        <div className="relative">
          {fullWordSearchTable && (
            <FilterSearchTable
              tableData={tableFullTextSearchData}
              tableCols={tableFullTextSearchColumns}
              handleChangePage={handleChangePage}
              offset={fullWordTextSearchRequestData?.offset || 0}
              limit={fullWordTextSearchRequestData.limit || 10}
            />
          )}
          <TableMetricRecords
            apRecords
            project_id={project?.project_id || ''}
            filterParams={filterParams}
            currentTemplate={currentTemplate}
            changeFilterParams={changeFilterParams}
            hide={!!fullWordSearchTable}
          />
        </div>
        {/*<ProjectRecordsTableBlock*/}
        {/*  project_id={project?.project_id || ''}*/}
        {/*  filterParams={filterParams}*/}
        {/*  changeFilterParams={changeFilterParams}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default LastProjectRecordsPage;
