import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  FullTextSearchResponseType,
  FullTextSearchRequestType,
} from '@app/interfaces/full-text-search.type';

import { baseQuery } from '../baseQuery';
export const fullTextSearchApi = createApi({
  reducerPath: 'fullTextSearchApi',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getFullWordSearch: build.query<FullTextSearchResponseType, Partial<FullTextSearchRequestType>>({
      query: (body) => ({
        method: 'POST',
        url: serverRoutes.full_text_search,
        body,
      }),
    }),
    getFullWordSearchByProject: build.query<
      FullTextSearchResponseType,
      Partial<FullTextSearchRequestType>
    >({
      query: (body) => ({
        method: 'POST',
        url: serverRoutes.full_text_search_by_project,
        body,
      }),
    }),
    getFullWordSearchChats: build.query<
      FullTextSearchResponseType,
      Partial<FullTextSearchRequestType>
    >({
      query: (body) => ({
        method: 'POST',
        url: serverRoutes.full_text_search_chat,
        body,
      }),
    }),
    getFullWordSearchByProjectChats: build.query<
      FullTextSearchResponseType,
      Partial<FullTextSearchRequestType>
    >({
      query: (body) => ({
        method: 'POST',
        url: serverRoutes.full_text_search_by_chat_project,
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetFullWordSearchQuery,
  useLazyGetFullWordSearchByProjectQuery,
  useLazyGetFullWordSearchByProjectChatsQuery,
  useLazyGetFullWordSearchChatsQuery,
} = fullTextSearchApi;
