module.exports = {
  pageTitle: 'Записи',
  records_for_delete_modal: 'записи',
  multi_delete_records_modal_label: 'Удалить выбранные',
  multi_delete_records_modal_description: 'Будут удалены все выбранные записи',
  record_delete_modal_label: 'Удалить запись',
  change_column_display_modal: {
    modal_label: 'Отображение колонок',
    modal_description: 'Описание',
    choose_cols: 'Выберите отображаемые колонки',
  },
  popup_hints: {
    upload_modal: 'Загрузить записи',
    data_display_set_up: 'Настроить отображение данных',
    download_record: 'Скачать аудиозапись',
    to_record_title: 'Перейти к просмотру записи',
    delete_icon: 'Удалить',
    go_to_audio: 'Перейти к просмотру записи',
  },
  system: {
    calls: 'звонков',
    preset_template_default_name: 'Введите название шаблона',
  },
  popover: {
    download_records: 'Выгрузить выбранные записи',
    delete_selected: 'Удалить выбранные',
    buttonLabel: 'Действия',
    delete_by_filter: 'Удалить все записи списка'
  },
  filter: {
    buttons: {
      save_as_template: 'Сохранить как шаблон пресета',
      reset_filter: 'Очистить'
    }
  },
  table: {
    first_column: 'Длительность',
    last_column: 'Действия'
  },
  remove_by_filter: {
    warning: 'Предупреждение',
    firstConfirmationFill: 'Внимание, будут удалены все записи списка с учетом примененного фильтра. Таким образом можно удалить ВСЕ записи из Системы без возможности их дальнейшего восстановления. В случае ошибочного удаления записи придётся заливать и обрабатывать заново. Вы уверены что хотите удалить записи?',
    secondConfirmationFill: 'Просим повторно подтвердить удаление записей списка с учетом примененного фильтра, без возможности их восстановления',
    confirm: 'Подтверждение'
  }
}
