import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { RecordsItems } from '@app/interfaces/records';
import serverRoutes from '@app/utils/server-routes';
import { RecordBulk } from '@app/interfaces/record';

import { Filter, GetFilterParamsNew, Range } from '@app/components/ui/easy-filter/types';

import fileDownload from 'js-file-download';

import { baseQuery } from '../baseQuery';

const recordsApi = createApi({
  reducerPath: 'recordsApi',
  tagTypes: ['Records', 'Record'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getRecords: build.query<RecordBulk, GetFilterParamsNew>({
      query: (body) => ({
        url: serverRoutes.recordList,
        body,
        method: 'POST',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.records.map(({ record_id }) => ({
                type: 'Records' as const,
                record_id,
              })),
              'Records',
            ]
          : ['Records'],
    }),
    // TODO change response type
    getRecord: build.query<RecordsItems, { id: string }>({
      query: (params) => ({
        url: serverRoutes.record,
        params,
      }),
    }),
    /* Records delete */
    deleteRecords: build.mutation<RecordsItems, { items: Array<string> }>({
      query: (body) => ({
        url: serverRoutes.record,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Records'],
    }),
    deleteRecordsByFilter: build.mutation<RecordsItems, Filter & Range>({
      query: (body) => ({
        url: serverRoutes.recordList,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Records'],
    }),
    /* record download */
    downloadRecord: build.query({
      query: (params: { record_id: string }) => ({
        url: serverRoutes.audio,
        params,
        responseHandler: async (response) => {
          const name = response.headers.get('content-disposition');
          const replacedName = name
            ? name.replace(/"/gim, '').replace('attachment; filename=', '')
            : '';
          await response.blob().then((blob) => {
            fileDownload(blob, replacedName);
          });
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetRecordsQuery,
  useLazyGetRecordsQuery,
  useGetRecordQuery,
  useDeleteRecordsMutation,
  useLazyDownloadRecordQuery,
  useDeleteRecordsByFilterMutation,
} = recordsApi;
export default recordsApi;
