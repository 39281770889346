import { Checkbox, EasyModal } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import React, { FC, useEffect, useState } from 'react';

type DeleteByFilterModalPropsType = {
  showFirstModal: boolean;
  onCloseFirstModal(): void;
  onConfirmRemove(): void;
};

const DeleteByFilterModal: FC<DeleteByFilterModalPropsType> = (props) => {
  const { t } = useTranslation('pages.allRecords.remove_by_filter');

  const { onCloseFirstModal, showFirstModal, onConfirmRemove } = props;
  const [firstCaptcha, setFirstCaptcha] = useState(false);
  const [secondCaptcha, setSecondCaptcha] = useState(false);
  const [secondModal, setSecondModal] = useState(false);

  function onFirstRemoveConfirmation() {
    onCloseFirstModal();
    setSecondModal(true);
  }

  function onLastRemoveConfirmation() {
    onCloseSecondModal();
    onConfirmRemove();
  }

  function onCloseSecondModal() {
    setSecondModal(false);
  }

  useEffect(() => {
    if (showFirstModal || secondModal) return;
    setFirstCaptcha(false);
    setSecondCaptcha(false);
  }, [secondModal, showFirstModal]);

  return (
    <>
      <EasyModal
        onClose={onCloseFirstModal}
        disabled={!firstCaptcha}
        onRemove={onFirstRemoveConfirmation}
        label={t('warning')}
        variant="removal"
        withoutFooter
        show={showFirstModal}
      >
        <div className="flex flex-col gap-[20px]">
          <span className="flex items-center text-center justify-center w-full">
            {t('firstConfirmationFill')}
          </span>
          <div className="flex items-center justify-center gap-[10px]">
            <span>{t('confirm')}</span>
            <Checkbox
              checked={firstCaptcha}
              onChange={(event) => setFirstCaptcha(event.target.checked)}
            />
          </div>
        </div>
      </EasyModal>
      <EasyModal
        onClose={onCloseSecondModal}
        disabled={!secondCaptcha}
        label={t('warning')}
        variant="removal"
        withoutFooter
        show={secondModal}
        onRemove={onLastRemoveConfirmation}
      >
        <div className="flex flex-col gap-[20px]">
          <span className="flex items-center text-center justify-center w-full">
            {t('secondConfirmationFill')}
          </span>
          <div className="flex items-center justify-center gap-[10px]">
            <span>{t('confirm')}</span>
            <Checkbox
              checked={secondCaptcha}
              onChange={(event) => setSecondCaptcha(event.target.checked)}
            />
          </div>
        </div>
      </EasyModal>
    </>
  );
};

export default DeleteByFilterModal;
