import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import metaSlice from '@app/store/reducers/meta.slice';
import { recognitionSettingsApi } from '@app/store/api/recognition-rules-settings-page.api';
import { asrModelApi } from '@app/store/api/asr_model.api';
import { dashboardApi } from '@app/store/api/dashboard-page.api';
import { dashboardChartsApi } from '@app/store/api/dashboard-charts.api';
import { chartsGraphDataApi } from '@app/store/api/graph-data.api';
import { metricRecordsApi } from '@app/store/api/metric-records.api';
import { analyticsProjectApi } from '@app/store/api/analytics-projects.api';
import { usersApi } from '@app/store/api/users.api';

import { presetTemplateApi } from '@app/store/api/preset-template.api';

import { customAliasesApi } from '@app/store/api/custom-aliases.api';

import { metricTemplateApi } from '@app/store/api/metric-template.api';

import { metricsApi } from '@app/store/api/metrics.api';

import { dictionariesApi } from '@app/store/api/dictionaries.api';

import { integrationSettingsApi } from '@app/store/api/intergation-settings.api';

import { liveReportsApi } from '@app/store/api/live-reports.api';

import { liveReportsChartsApi } from '@app/store/api/live-reports-charts.api';

import { analyticsProjectPresetApi } from '@app/store/api/analytics-project-preset.api';

import { wordsNGramApi } from '@app/store/api/get-words-n-gramm.api';

import userSettingsSlice from '@app/store/reducers/user-settings.slice';

import { userSettingsApi } from '@app/store/api/user-settings.api';

import { manualRecordingUpload } from '@app/store/api/menual-recording-upload';

import { audioRecordApi } from '@app/store/api/audio-record.api';

import dashboardChartsSlice from '@app/store/reducers/dashboard-charts.slice';

import { informMessagesApi } from '@app/store/api/inform-messages.api';

import { metricTriggerApi } from '@app/store/api/metric-trigger.api';

import { tenantRecipientsApi } from '@app/store/api/tenant-recipients.api';

import { allChatsApi } from '@app/store/api/chat-api/all-chats.api';
import { chatAnalyticProjectApi } from '@app/store/api/chat-api/chat-analytic.api';

import recordsApi from './api/records.api';
import restMiddleware from './middleware/rest';
import recordsSlice from './reducers/recordsSlice';
import { RootState } from './../interfaces/common';
import metricSlice from './reducers/metric-slice';
import audioChannelsSlice from './reducers/audio-channels.slice';
import { userInfoApi } from './api/get-user-info.api';
import { channelsInfoApi } from './api/get-channels-info.api';
import { wordsCloudApi } from './api/get-words-cloud.api';
import { auditLogsApi } from './api/audit-logs.api';
import { commonSettingsApi } from './api/common-settings-page.api';
import { aliasSettingsApi } from './api/alias-settings-page.api';
import reportChartsSlice from './reducers/report-charts.slice';
import { reportRecipientsApi } from './api/report_recipients.api';
import { staticReportApi } from './api/get-static-report.api';
import { chatMetricApi } from './api/chat-api/chat-metric.api';
import { chatRolesApi } from './api/chat-api/get-chat-roles.api';
import { chatLiveReportsApi } from './api/chat-api/live-reports.api';
import chatReportChartsSlice from './reducers/chat-report-charts.slice';
import { chatLiveReportsChartsApi } from './api/chat-api/chat-live-reports-charts.api';
import { fullTextSearchApi } from './api/get-full-text-search.api';

const store = configureStore({
  reducer: {
    records: recordsSlice,
    metric: metricSlice,
    meta: metaSlice,
    audioChannels: audioChannelsSlice,
    userSettings: userSettingsSlice,
    dashboardCharts: dashboardChartsSlice,
    reportCharts: reportChartsSlice,
    chatReportCharts: chatReportChartsSlice,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [channelsInfoApi.reducerPath]: channelsInfoApi.reducer,
    [wordsCloudApi.reducerPath]: wordsCloudApi.reducer,
    [auditLogsApi.reducerPath]: auditLogsApi.reducer,
    [commonSettingsApi.reducerPath]: commonSettingsApi.reducer,
    [aliasSettingsApi.reducerPath]: aliasSettingsApi.reducer,
    [asrModelApi.reducerPath]: asrModelApi.reducer,
    [recognitionSettingsApi.reducerPath]: recognitionSettingsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [dashboardChartsApi.reducerPath]: dashboardChartsApi.reducer,
    [chartsGraphDataApi.reducerPath]: chartsGraphDataApi.reducer,
    [metricRecordsApi.reducerPath]: metricRecordsApi.reducer,
    [analyticsProjectApi.reducerPath]: analyticsProjectApi.reducer,
    [recordsApi.reducerPath]: recordsApi.reducer,
    [presetTemplateApi.reducerPath]: presetTemplateApi.reducer,
    [customAliasesApi.reducerPath]: customAliasesApi.reducer,
    [metricTemplateApi.reducerPath]: metricTemplateApi.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
    [dictionariesApi.reducerPath]: dictionariesApi.reducer,
    [integrationSettingsApi.reducerPath]: integrationSettingsApi.reducer,
    [liveReportsApi.reducerPath]: liveReportsApi.reducer,
    [liveReportsChartsApi.reducerPath]: liveReportsChartsApi.reducer,
    [analyticsProjectPresetApi.reducerPath]: analyticsProjectPresetApi.reducer,
    [wordsNGramApi.reducerPath]: wordsNGramApi.reducer,
    [userSettingsApi.reducerPath]: userSettingsApi.reducer,
    [manualRecordingUpload.reducerPath]: manualRecordingUpload.reducer,
    [audioRecordApi.reducerPath]: audioRecordApi.reducer,
    [informMessagesApi.reducerPath]: informMessagesApi.reducer,
    [metricTriggerApi.reducerPath]: metricTriggerApi.reducer,
    [tenantRecipientsApi.reducerPath]: tenantRecipientsApi.reducer,
    [reportRecipientsApi.reducerPath]: reportRecipientsApi.reducer,
    [staticReportApi.reducerPath]: staticReportApi.reducer,
    [allChatsApi.reducerPath]: allChatsApi.reducer,
    [chatAnalyticProjectApi.reducerPath]: chatAnalyticProjectApi.reducer,
    [chatMetricApi.reducerPath]: chatMetricApi.reducer,
    [chatRolesApi.reducerPath]: chatRolesApi.reducer,
    [chatLiveReportsApi.reducerPath]: chatLiveReportsApi.reducer,
    [chatLiveReportsChartsApi.reducerPath]: chatLiveReportsChartsApi.reducer,
    [fullTextSearchApi.reducerPath]: fullTextSearchApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      usersApi.middleware,
      userInfoApi.middleware,
      recognitionSettingsApi.middleware,
      commonSettingsApi.middleware,
      dashboardApi.middleware,
      aliasSettingsApi.middleware,
      wordsCloudApi.middleware,
      channelsInfoApi.middleware,
      auditLogsApi.middleware,
      metricRecordsApi.middleware,
      dashboardChartsApi.middleware,
      chartsGraphDataApi.middleware,
      asrModelApi.middleware,
      analyticsProjectApi.middleware,
      recordsApi.middleware,
      presetTemplateApi.middleware,
      customAliasesApi.middleware,
      metricTemplateApi.middleware,
      metricsApi.middleware,
      chatRolesApi.middleware,
      dictionariesApi.middleware,
      integrationSettingsApi.middleware,
      liveReportsApi.middleware,
      liveReportsChartsApi.middleware,
      analyticsProjectPresetApi.middleware,
      wordsNGramApi.middleware,
      userSettingsApi.middleware,
      manualRecordingUpload.middleware,
      audioRecordApi.middleware,
      informMessagesApi.middleware,
      metricTriggerApi.middleware,
      tenantRecipientsApi.middleware,
      reportRecipientsApi.middleware,
      staticReportApi.middleware,
      allChatsApi.middleware,
      chatAnalyticProjectApi.middleware,
      chatMetricApi.middleware,
      chatLiveReportsApi.middleware,
      chatLiveReportsChartsApi.middleware,
      fullTextSearchApi.middleware,
      restMiddleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
