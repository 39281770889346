import React, { FC } from 'react';

import RubberTable from '../rubber-table';
import { TableColumn, TableItem } from '../rubber-table/table.types';
import Icon from '../ui/icons';

type FilterSearchTablePropsType = {
  tableData: TableItem[];
  tableCols: TableColumn[];
  handleChangePage(type: 'next' | 'prev'): void;
  offset: number;
  limit: number;
};

const FilterSearchTable: FC<FilterSearchTablePropsType> = (props) => {
  const { tableData, tableCols, handleChangePage, offset, limit } = props;
  return (
    <div className="flex flex-col items-start gap-[10px]">
      <RubberTable specialPropForSearchTable dataSource={tableData} columns={tableCols} />
      {/* pagination / */}
      <div className="bg-white flex h-[40px] overflow-hidden items-center shadow-medium font-[700] text-[14px] rounded-[10px]">
        <div
          onClick={() => (offset || 0) > 0 && handleChangePage('prev')}
          className="bg-white cursor-pointer h-full  items-center justify-center flex min-h-[54px] w-[33px] hover:bg-b_dark border-r-[1px] border-b_light"
        >
          {/* disable={(offset || 0) <= 0} */}
          <Icon name="ChevronLeftV2Icon" size={8} />
        </div>
        <div className="bg-speech_analitics text-white transition mx-[12px]  select-none ease-in-out px-[10px] py-[3px] my-[7px] rounded-[10px]">
          {offset / limit + 1}
        </div>
        <div
          onClick={() => handleChangePage('next')}
          className="bg-white cursor-pointer h-full  items-center justify-center flex min-h-[54px] w-[33px] hover:bg-b_dark border-l-[1px] border-b_light"
        >
          <Icon name="ChevronLeftV2Icon" className="rotate-180" size={8} />
        </div>
      </div>
    </div>
  );
};

export default FilterSearchTable;
