import { WordsListComponent } from '@app/components/add-words-and-dictionaries-container/wordsListComponent';
import { Box, Button, EditText, Modal, PageHeader } from '@app/components/ui';
import { CreateDictionaryType } from '@app/interfaces/slices-types/dictionaries-slice.type';
import { getFrontendDate } from '@app/utils/helpers';
import { navigationRoutes } from '@app/utils/navigation-routes';
import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import useTranslation from '@app/hooks/use-translation';
import { toast } from 'react-toastify';
import { NameAndDescriptionPattern } from '@app/constants/validation/regexp';
import {
  useCreateDictionaryMutation,
  useDeleteDictionaryMutation,
  useEditDictionaryMutation,
  useGetAllDictionariesListQuery,
  useGetDictionaryQuery,
} from '@app/store/api/dictionaries.api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { DictionariesSkeleton } from '@app/pages/dictionaries-page/blocks/dictionaries-skeleton';
import { NavLink } from 'react-router-dom';

type PropsType = {
  edit: boolean;
  copy: boolean;
  newDictionary: boolean;
};

export const CreateDictionaries: React.FC<PropsType> = ({
  edit = false,
  copy = false,
  newDictionary = false,
}) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.dictionaries');
  const { t: tf } = useTranslation('common.forms');
  const navigate = useNavigate();
  //useState
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [wordsListArray, setWordsListArray] = React.useState<string[]>([]);
  const [dictionariesListArray, setDictionariesListArray] = React.useState<string[]>([]);
  const [visibleInput, setVisibleInput] = React.useState(newDictionary);
  const [dictionaryName, setDictionaryName] = React.useState<string>();
  const [description, setDescription] = React.useState<string>();
  // api
  const { data: dictionary, isLoading: dictionaryLoading } = useGetDictionaryQuery(
    { id: id || '' },
    { skip: !id },
  );
  const { data: dictionaries, isLoading: dictionariesLoading } = useGetAllDictionariesListQuery();
  const [createDictionary] = useCreateDictionaryMutation();
  const [editDictionary] = useEditDictionaryMutation();
  const [deleteDictionary] = useDeleteDictionaryMutation();

  const getDictionaryName = React.useCallback(() => {
    if (!dictionaries) return;
    if (dictionaries.length >= 9) {
      setDictionaryName(t('dictionary') + ' 0' + (dictionaries.length + 1));
    } else if (dictionaries.length >= 99) {
      setDictionaryName(t('dictionary') + ' ' + (dictionaries.length + 1));
    } else {
      setDictionaryName(t('dictionary') + ' 00' + (dictionaries.length + 1));
    }
  }, [dictionaries, t]);

  React.useEffect(() => {
    setDictionariesListArray([]);
    setWordsListArray([]);
    if (edit) {
      setDescription(dictionary?.description);
      setDictionaryName(dictionary?.name);
      if (dictionary?.items) {
        setWordsListArray(dictionary?.items);
      }
      if (dictionary?.parents) {
        setDictionariesListArray(dictionary?.parents.map((e) => e.dictionary_id));
      }
    } else if (newDictionary) {
      getDictionaryName();
    } else if (copy && dictionary?.name) {
      setDictionaryName(dictionary?.name + ' ' + t('copy'));
      setDescription(dictionary?.description);
      if (dictionary?.items) {
        setWordsListArray(dictionary?.items);
      }
      if (dictionary?.parents) {
        setDictionariesListArray(dictionary?.parents.map((e) => e.dictionary_id));
      }
    }
  }, [
    copy,
    dictionary?.description,
    dictionary?.items,
    dictionary?.name,
    dictionary?.parents,
    edit,
    getDictionaryName,
    newDictionary,
    t,
  ]);

  const inputVisibleChange = (e: string) => {
    setVisibleInput(!visibleInput);
    const value = e.replace(NameAndDescriptionPattern, '');
    setDictionaryName(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (dictionaryName && description) {
      const dictionaryObject: CreateDictionaryType = {
        name: dictionaryName,
        description: description,
        items: wordsListArray,
        parents: dictionariesListArray,
      };
      if (edit && id) {
        editDictionary({ body: dictionaryObject, params: { id } }).then(() => {
          navigate(`/${navigationRoutes.dictionaries}`);
        });
      } else {
        createDictionary(dictionaryObject).then(() => {
          navigate(`/${navigationRoutes.dictionaries}`);
        });
      }
    } else {
      toast.error(t('fillAllFields'));
    }
  };

  // const inputFocusRef = AutoFocusHook(newDictionary);
  const ref = useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (newDictionary) {
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    } else if (visibleInput) {
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    }
  }, [newDictionary, visibleInput]);
  // const onDictionaryClick = (e: string) => {
  //   navigate(`/${navigationRoutes.dictionaries}/${e}/edit`);
  //   setDictionariesListArray([]);
  //   setWordsListArray([]);
  // };
  function disableButtonDelete() {
    if (dictionary?.is_system) return true;
    return dictionary?.childs !== null;
  }
  function saveButtonDisable() {
    if (edit) {
      return dictionary?.is_system;
    } else return false;
  }
  function handleClearAllWords() {
    setWordsListArray([]);
    setDictionariesListArray([]);
  }
  function onApplyDictionaryDelete() {
    id &&
      deleteDictionary({ id }).then((data) => {
        const err = (
          data as {
            error: FetchBaseQueryError;
          }
        ).error;
        err.status === 403 && toast.info(t('dictDeleteToastError'));
      });

    setDeleteModal(false);
  }
  if (dictionaryLoading || dictionariesLoading) return <DictionariesSkeleton variant={2} />;
  return (
    <>
      <PageHeader
        popLink={'/' + navigationRoutes.dictionaries}
        label={
          <div className="flex items-center gap-[18px]">
            {dictionaryName && (
              <EditText
                defaultOpen={newDictionary || copy}
                refCondition={newDictionary}
                editIconSize={18.5}
                text={dictionaryName}
                onEdited={inputVisibleChange}
              />
            )}
          </div>
        }
      ></PageHeader>
      <Box className="p-[40px]">
        <form onSubmit={(e) => e.preventDefault()} className="flex gap-[20px] flex-col w-full">
          <div className="w-full flex items-start gap-[15px] justify-between">
            <span className="text-[15px] whitespace-nowrap text-0color">{t('dict_desc')}</span>
            <div className="w-full relative">
              <textarea
                className={`${
                  !description && '!border-basic_red !focus:border-basic_red'
                } w-full bg-white text-0color border-b_dark focus:border-b_dark rounded-defaultR`}
                rows={2}
                defaultValue={description || ''}
                onChange={(e) =>
                  setDescription(e.target.value.replace(NameAndDescriptionPattern, ''))
                }
                placeholder={t('input')}
                maxLength={200}
                name={'description'}
              />
              {!description && (
                <div className=" whitespace-nowrap absolute text-sm text-red-600 dark:text-red-500 bottom-[-15px]">
                  {tf('required')}
                </div>
              )}
            </div>
          </div>

          <div>
            <WordsListComponent
              currentDictionaryId={edit ? dictionary?.dictionary_id : undefined}
              dictionariesListArray={dictionariesListArray}
              setDictionariesListArray={setDictionariesListArray}
              className="min-h-[322px] "
              wordsListArray={wordsListArray}
              setWordsListArray={setWordsListArray}
              dictionaries={dictionaries}
            />
          </div>
          {!newDictionary && (
            <div className="flex flex-col gap-[30px]">
              <div className="flex items-center justify-between w-[285px]">
                <span className="text-[15px] text-0color">{t('date_update')}</span>
                <span className="text-[15px] text-0color">
                  {(edit || copy) && dictionary
                    ? getFrontendDate(dictionary.dt_update, 'dd.MM.yyyy')
                    : null}
                </span>
              </div>
              {dictionary?.childs !== null && !copy && (
                <div className="flex flex-col gap-[15px]">
                  <span className="text-[15px] text-0color">{t('dict_used')}</span>
                  <div className="flex gap-[10px] items-center w-full flex-wrap">
                    {dictionary?.childs.map((child) => {
                      return (
                        <NavLink
                          to={`/${navigationRoutes.dictionaries}/${child.dictionary_id}/edit`}
                          target={'_blank'}
                          title={t('popup_hints.to_dictionary')}
                          key={child.dictionary_id}
                          className="cursor-pointer p-[2px_8px_3px] bg-[#FFE2F4] rounded-[4px]"
                        >
                          <span className="text-0color underline">{child.name}</span>
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-[12px]">
              <Button
                onClick={(e) => onSubmit(e)}
                disabled={saveButtonDisable()}
                label={t('save')}
              />
              <Button
                link={'/' + navigationRoutes.dictionaries}
                fill="linked"
                label={t('cancel')}
              />
              {edit && (
                <Button
                  link={`/${navigationRoutes.dictionaries}/${id}/copy`}
                  icon="CopyIcon"
                  fill="outlined"
                  label={t('copy_dict')}
                />
              )}
            </div>
            <div className={'flex items-center'}>
              <div>
                <Button
                  onClick={handleClearAllWords}
                  variant="danger"
                  fill={'linked'}
                  label={t('remove_all')}
                />
              </div>
              {!newDictionary && (
                <Button
                  icon="TrashIcon"
                  label={t('delete')}
                  variant="danger"
                  onClick={() => setDeleteModal(true)}
                  fill="linked"
                  disabled={disableButtonDelete()}
                />
              )}
            </div>
          </div>
        </form>
      </Box>
      <Modal
        id={'deleteDictionary'}
        variant={'removal'}
        value={deleteModal}
        setValue={setDeleteModal}
        title={t('warning')}
        size={'xs'}
        onApply={onApplyDictionaryDelete}
      >
        <div className="px-[50px]">{t('dict_confirm_delete')}</div>
      </Modal>
    </>
  );
};
