module.exports = {
  full_word_search: 'Свободный поиск',
  search_placeholder: 'Введите текст для поиска ...',
  search_eye_hint: 'Очистить поиск и скрыть',
  table: {
    date: 'Дата/время начала записи',
    text: 'Текст',
    channel: 'Канал',
    crole_name: 'Роль',
    model: 'Модель',
    actions: 'Действия',
  },
  popup_hints:{
    to_record_title:'Перейти к просмотру записи'
  }
}