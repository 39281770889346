const loading = require('./loading');
const record = require('./record');
const paginator = require('./paginator');
const empty = require('./empty');
const reportsStatusLabel = require('./reportsStatusLabel');
const footer = require('./footer');
const easyModal = require('./easyModal');
const chartEditModal = require('./chartEditModal');
const audio = require('./audio');
const recordTableWithFilter = require('./recordTableWithFilter');
const filter = require('./filter');
const wordListComponent = require('./wordListComponent');
const menuBar = require('./menuBar');
const navigateTabs = require('./navigateTabs');
const selectUniversalFilter = require('./line-select-universal-filter')
const header = require('./header')
const upload = require('./upload')
const recipientsModal = require('./recipientsModal')
const filterSearch = require('./filterSearch')

module.exports = {
  loading,
  record,
  empty,
  paginator,
  reportsStatusLabel,
  footer,
  easyModal,
  chartEditModal,
  audio,
  recordTableWithFilter,
  filter,
  wordListComponent,
  menuBar,
  navigateTabs,
  selectUniversalFilter,
  header,
  upload,
  recipientsModal,
  filterSearch
};