import { Box, Button, Input, Select } from '@app/components/ui';
import React, { FC, useState } from 'react';

import { useForm } from 'react-hook-form';

import { AllChanelsArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { NavLink, useLocation } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';

import { CloudIcon, MenuSmallIcon } from '@app/components/ui/icons/icons-list';
import {
  useStartCalculateChatWordNGramCloudMutation,
  useStartCalculateWordNGramCloudMutation,
  useUpdateChatWordsNGramStaticCloudSettingsMutation,
  useUpdateWordsNGramStaticCloudSettingsMutation,
} from '@app/store/api/get-words-n-gramm.api';
import {
  WordNGramStartPointType,
  WordNGramStatusesType,
  WordsNGramSettings,
  WordsNGramSettingsResponse,
  WordsNGramStaticRequestType,
} from '@app/interfaces/words-n-gramm.type';
import useTranslation from '@app/hooks/use-translation';
import { WordsListComponent } from '@app/components/add-words-and-dictionaries-container/wordsListComponent';
import { DictionariesType } from '@app/interfaces/slices-types/dictionaries-slice.type';
import { CircleButton, Toggle, TooltipWrapper } from '@ui';
import { AnalyticsProjectInReportType } from '@app/interfaces/report.type';
import { ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';
import { OptionItem } from '@app/components/ui/select/select.type';

type WordsCloudFilterPropsType = {
  channels?: AllChanelsArrType[];
  chatRoles?: ChatRoleSettingsType[];
  onFormSubmit?: (data: Omit<WordsNGramStaticRequestType, 'live_report_id'>) => void;
  disableSaveButton?: boolean;
  projectId: string | undefined;
  dictionaries: DictionariesType[];
  cloudSettings: WordsNGramSettingsResponse | undefined;
  refetchCloudStatus?(): void;
  cloudStatus: WordNGramStatusesType | undefined;
  analyticsProjectByReport?: AnalyticsProjectInReportType;
  currentTemplate: 'chat' | 'voice';
};
export const WordsCloudFilter: FC<WordsCloudFilterPropsType> = (props) => {
  const { t } = useTranslation('pages.wordsNGramm');
  const location = useLocation();
  const {
    channels,
    projectId,
    refetchCloudStatus,
    dictionaries,
    onFormSubmit,
    cloudSettings,
    disableSaveButton,
    cloudStatus,
    currentTemplate,
    chatRoles,
  } = props;
  const startpointTypeOptions = [
    { title: t('startpoint_values.begin'), value: 'begin' },
    { title: t('startpoint_values.end'), value: 'end' },
    { title: t('startpoint_values.after_phrase'), value: 'after_phrase' },
    { title: t('startpoint_values.before_phrase'), value: 'before_phrase' },
  ];
  //api
  const [cloudDeepEditMode, changeCloudDeepEditMode] = useState(false);
  const [updateSettingsVoice] = useUpdateWordsNGramStaticCloudSettingsMutation();
  const [startCalculateVoice] = useStartCalculateWordNGramCloudMutation();
  const [updateSettingsChat] = useUpdateChatWordsNGramStaticCloudSettingsMutation();
  const [startCalculateChat] = useStartCalculateChatWordNGramCloudMutation();

  const updateSettings = { chat: updateSettingsChat, voice: updateSettingsVoice };
  const startCalculate = { chat: startCalculateChat, voice: startCalculateVoice };

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ filter: string; limit: number } & WordsNGramSettings>({
    defaultValues: {
      limit: 100,
      // frequency: Number(getOccurrenceOptions()[0].value)
      ...{
        ...cloudSettings?.settings,
        channels: cloudSettings?.settings.channels?.length
          ? cloudSettings?.settings.channels
          : channels?.map((i) => i.channel),
        c_roles: cloudSettings?.settings.c_roles?.length
          ? cloudSettings?.settings.c_roles
          : chatRoles?.map((i) => i.crole_id),
        stopwords: {
          all_word_forms: cloudSettings?.settings.stopwords?.all_word_forms || true,
          dictionaries: cloudSettings?.settings.stopwords?.dictionaries || [],
          wordlist: cloudSettings?.settings.stopwords?.wordlist || [],
        },
        startpoint: {
          type: cloudSettings?.settings.startpoint?.type || 'begin',
          find: {
            channels: cloudSettings?.settings.startpoint?.find?.channels?.length
              ? cloudSettings?.settings.startpoint?.find?.channels
              : channels?.map((i) => i.channel),
            c_roles: cloudSettings?.settings.startpoint?.find?.c_roles?.length
              ? cloudSettings?.settings.startpoint?.find?.c_roles
              : chatRoles?.map((i) => i.crole_id),
            all_word_forms: cloudSettings?.settings.startpoint?.find?.all_word_forms || true,
            dictionaries: cloudSettings?.settings.startpoint?.find?.dictionaries || [],
            wordlist: cloudSettings?.settings.startpoint?.find?.wordlist || [],
          },
        },
      },
    },
  });
  function onGetCloudData() {
    const filterValue: string = watch('filter');
    // const decoder = new TextDecoder('utf-8');
    const decodedMessage = encodeURI(filterValue).split(' ');

    onFormSubmit?.({ filter: decodedMessage as unknown as string, limit: watch('limit') });
  }
  function onSubmit(data: WordsNGramSettings) {
    const formattedData = {
      ...data,
      channels: data.channels?.map(Number),
      c_roles: data.c_roles,
      startpoint: {
        ...data.startpoint,
        type: data.startpoint.type,
        find: {
          ...data.startpoint.find,
          channels: data.startpoint.find.channels?.map(Number),
          c_roles: data.startpoint.find.c_roles,
        },
      },
    };
    const formValues = Object.keys(formattedData)
      .filter((objKey) => objKey !== 'limit' && objKey !== 'filter')
      .reduce((newObj, key) => {
        newObj[key] = formattedData[key];
        return newObj;
      }, {});
    updateSettings[currentTemplate]({
      body: { settings: formValues as WordsNGramSettings },
      params: { live_report_id: projectId || '' },
    })
      .unwrap()
      .then(() => {
        startCalculate[currentTemplate]({ live_report_id: projectId || '' });
        refetchCloudStatus?.();
      });
  }
  function getChannelsOptions(): OptionItem[] {
    return channels
      ? channels?.map((item) => ({ title: item.name, value: item.channel }))
      : chatRoles
      ? chatRoles.map((role) => ({ title: role.name || '', value: role.crole_id || '' }))
      : [];
  }

  function changeStopWordsArray(words: string[]) {
    setValue('stopwords.wordlist', words);
  }
  function changeStopDictionariesArray(dictionaries: string[]) {
    setValue('stopwords.dictionaries', dictionaries);
  }

  function changeStartPointDictionariesArray(dictionaries: string[]) {
    setValue('startpoint.find.dictionaries', dictionaries);
  }
  function changeStartPointWordsArray(words: string[]) {
    setValue('startpoint.find.wordlist', words);
  }
  async function handleSaveCloudDeep() {
    await onGetCloudData();
    setTimeout(() => changeCloudDeepEditMode(false), 400);
  }

  const startPointChannelsSelectProps = {
    onChangeMultiple: (value) => setValue('startpoint.find.channels', value as string[]),
    defaultValue: (watch().startpoint.find?.channels as string[]) || [],
  };
  const startPointRolesSelectProps = {
    onChangeMultiple: (value) => setValue('startpoint.find.c_roles', value as string[]),
    defaultValue: (watch().startpoint.find?.c_roles as string[]) || [],
  };
  const channelsSelectProps = {
    onChangeMultiple: (value) => setValue('channels', value as string[]),
    defaultValue: (watch().channels as string[]) || [],
  };
  const rolesSelectProps = {
    onChangeMultiple: (value) => setValue('c_roles', value as string[]),
    defaultValue: (watch().c_roles as string[]) || [],
  };
  channels;
  const channelsOrRoleSelectProps = channels ? channelsSelectProps : rolesSelectProps;
  const startPointSelectProps = channels
    ? startPointChannelsSelectProps
    : startPointRolesSelectProps;

  const cloudDeepAndCalculateDateBlock = (
    <div className="flex relative items-center w-full  justify-stretch flex-wrap-reverse gap-[5px] h-[40px]">
      <div className="flex w-fit whitespace-nowrap text-[12px] tracking-tight font-[500]">
        {t('cloud_deep')}
      </div>

      {cloudDeepEditMode ? (
        <div className="w-[110px]">
          <Input
            {...register('limit', {
              valueAsNumber: true,
            })}
            icon="CancelIcon"
            secondIconWithoutBg
            secondOnClick={handleSaveCloudDeep}
            secondIcon="CheckIcon"
            onClick={() => setValue('limit', 100)}
            iconColor="primary"
            secondIconClassName="!text-action"
            name={'limit'}
          />
        </div>
      ) : (
        <div className="whitespace-nowrap text-[12px] tracking-tight font-[500]">
          {watch('limit')}
        </div>
      )}

      {watch('limit') > 100 && (
        <span className="text-basic_red w-full text-[12px] absolute bottom-0 left-0">
          max is 100
        </span>
      )}

      <CircleButton
        icon="SettingIcon"
        className="text-3color"
        onClick={changeCloudDeepEditMode.bind(null, !cloudDeepEditMode)}
      />
    </div>
  );
  const findInPhrasesBeforeBlock = (
    <div className="flex flex-col gap-[10px] ">
      <div className="w-full relative">
        <WordsListComponent
          scroll
          dictionaries={dictionaries}
          className={'!min-w-full max-w-[100px] !min-h-[90px] !max-h-[90px]'}
          wordsListArray={watch('startpoint.find.wordlist') || []}
          setWordsListArray={changeStartPointWordsArray}
          dictionariesListArray={watch('startpoint.find.dictionaries') || []}
          setDictionariesListArray={changeStartPointDictionariesArray}
          withoutLabel
          additionalMarkup={
            <div className="flex items-center mt-[10px] gap-[3px]">
              <label className="ml-[6px] whitespace-nowrap mb-[2px] text-[11px] font-[500] ">
                {t('form.fields_labels.all_forms')}
              </label>
              <Toggle
                size="medium"
                checked={watch('startpoint.find.all_word_forms')}
                onChecked={(value) => setValue('startpoint.find.all_word_forms', value)}
              />
            </div>
          }
        />
        {/*absolute bottom-0 right-0 p-[0px_5px_5px_0px]?*/}
      </div>
      <div className="flex items-start justify-between">
        <div className="w-full">
          <div className="flex  items-center justify-between ">
            <label className="block ml-[6px] mb-[2px] text-[12px] font-[700] ">
              {t('form.fields_labels.startpoint_channels')}
            </label>
          </div>
          <Select
            {...startPointSelectProps}
            dropWidth={165}
            options={getChannelsOptions()}
            isMulti
            additionalLabelToEveryValue={t('form.channel')}
          />
        </div>
      </div>
    </div>
  );

  const cloudPathByTemplate = {
    chat: navigationRoutes.chatWordsNGramm,
    voice: navigationRoutes.wordsNGramm,
  };

  const generalDisplaySettingsMarkup = (
    <div className="flex px-[10px] gap-[5px] w-full">
      <div className={'w-full flex items-center gap-[5px]'}>
        <div className="w-full">
          <Input
            {...register('filter', {
              pattern: {
                value: /^[A-Za-zА-Яа-яёЁ]+$|^[A-Za-zА-Яа-яёЁ]+\s[A-Za-zА-Яа-яёЁ]+$/,
                message: t('filter_error_input_message'),
              },
            })}
            error={errors.filter?.message}
            placeholder={t('form.fields_placeholders.filter_input')}
            icon={'CheckIcon'}
            onClick={onGetCloudData}
            iconColor="primary"
            iconDisable={!disableSaveButton || cloudStatus !== 'READY'}
          />
        </div>
      </div>
      <div className="flex items-center gap-[5px]">
        <NavLink
          end
          to={`/${cloudPathByTemplate[currentTemplate]}`}
          className={(active) =>
            `${active.isActive && 'bg-action/[0.1]'} hover:bg-action/[0.1] p-[3.5px]`
          }
        >
          {({ isActive }) => (
            <CloudIcon
              className={`${isActive && '!text-action'} p-[2px] text-3color hover:text-action`}
              size={21}
            />
          )}
        </NavLink>
        <NavLink
          end
          to={`/${cloudPathByTemplate[currentTemplate]}/table`}
          className={(active) =>
            `${active.isActive && 'bg-action/[0.1]'} hover:bg-action/[0.1] p-[3.5px]`
          }
        >
          {({ isActive }) => (
            <MenuSmallIcon
              className={`${isActive && '!text-action'} p-[2px] text-3color hover:text-action`}
              size={21}
            />
          )}
        </NavLink>
      </div>
    </div>
  );
  const cloudSettingsBlockMarkup = (
    <div className="flex flex-col gap-[5px]">
      <div>
        <label className="block ml-[6px] mb-[2px] text-[12px] font-[700] ">
          {t('form.fields_labels.in_channels_find')}
        </label>
        <Select
          {...channelsOrRoleSelectProps}
          dropWidth={292}
          options={getChannelsOptions()}
          isMulti
          additionalLabelToEveryValue={t('form.channel')}
        />
      </div>
      <hr className="mt-[5px]" />
      <div>
        <label className="ml-[6px] mb-[2px] text-[12px] font-[700]">
          {t('form.fields_labels.startpoint_type')}
        </label>
        <div className="flex items-center gap-[5px]">
          <Input
            {...register('num_phrases', {
              validate: (value) => value <= 1000 || '',
              valueAsNumber: true,
              required: { message: 'err', value: true },
            })}
            error={watch('num_phrases') > 1000 ? 'max is 1000' : ''}
            errorWithoutMessage={!!errors.num_phrases?.message}
          />
          <label className="ml-[6px] mb-[2px] text-[11px] ">
            {t('form.fields_labels.search_area_number_phrases')}
          </label>
        </div>
      </div>
      <div>
        <Select
          dropWidth={292}
          options={startpointTypeOptions}
          onChange={(value) => setValue('startpoint.type', value as WordNGramStartPointType)}
          defaultValue={watch().startpoint.type || ''}
        />
      </div>
      {(watch('startpoint.type') === 'after_phrase' ||
        watch('startpoint.type') === 'before_phrase') && <>{findInPhrasesBeforeBlock}</>}
    </div>
  );

  const stopWordsBlockMarkup = (
    <div className={'w-full'}>
      <div className="flex items-center justify-between">
        <label className="ml-[6px] mb-[2px] text-[12px] font-[700] ">
          {t('form.fields_labels.stopwords')}
        </label>
      </div>
      <div className="relative">
        <WordsListComponent
          scroll
          dictionaries={dictionaries}
          className={'!min-w-full !min-h-[90px] !max-h-[90px]'}
          wordsListArray={watch('stopwords.wordlist') || []}
          setWordsListArray={changeStopWordsArray}
          dictionariesListArray={watch('stopwords.dictionaries') || []}
          setDictionariesListArray={changeStopDictionariesArray}
          withoutLabel
          additionalMarkup={
            <div className="flex items-center gap-[3px] h-fit mt-[10px]">
              <label className="ml-[6px] whitespace-nowrap mb-[2px] text-[11px] font-[500] ">
                {t('form.fields_labels.all_forms')}
              </label>
              <Toggle
                size="medium"
                checked={watch('stopwords.all_word_forms')}
                onChecked={(value) => setValue('stopwords.all_word_forms', value)}
              />
            </div>
          }
        />
      </div>
    </div>
  );
  const newExcludeBlock = (
    <div>
      <div className="flex flex-col gap-[5px]">
        <label className="ml-[6px] mb-[2px] text-[12px] font-[700]">
          {t('form.fields_labels.exclude_from_search')}
        </label>
        <div className="text-[11px] tracking-tight font-[500] flex justify-stretch gap-[10px] items-center">
          <div>
            <Input
              {...register('exclude_begin_phr', {
                validate: (value) => value <= 1000 || '',
                valueAsNumber: true,
                required: { message: 'err', value: true },
              })}
              error={watch('exclude_begin_phr') > 1000 ? 'max is 1000' : ''}
              errorWithoutMessage={!!errors.exclude_begin_phr?.message}
            />
          </div>
          <span>{t('form.fields_labels.exclude_first')}</span>
          <div>
            <Input
              {...register('exclude_end_phr', {
                validate: (value) => value <= 1000 || '',
                valueAsNumber: true,
                required: { message: 'err', value: true },
              })}
              error={watch('exclude_end_phr') > 1000 ? 'max is 1000' : ''}
              errorWithoutMessage={!!errors.exclude_end_phr?.message}
            />
          </div>
          <span className="whitespace-nowrap">{t('form.fields_labels.exclude_last')}</span>
        </div>
      </div>
    </div>
  );
  return (
    <form className="w-full h-full flex gap-[10px]">
      {location.pathname.split('/').reverse()[0] !== 'table' && (
        <div
          className={`absolute bottom-0 flex items-center  transition-all duration-[1000ms] ${
            cloudDeepEditMode ? 'left-[calc(100%-44em)]' : 'left-[calc(100%-38.5em)]'
          } z-10`}
        >
          {cloudDeepAndCalculateDateBlock}
        </div>
      )}
      <Box className="w-full h-[80px] flex items-center justify-stretch">
        {generalDisplaySettingsMarkup}
      </Box>
      <Box className="px-[20px] flex flex-col gap-[5px] py-[5px] min-w-[400px] max-w-[400px] h-full">
        {cloudSettingsBlockMarkup}
        <hr className="mt-[5px]" />
        {newExcludeBlock}
        {stopWordsBlockMarkup}
        <hr className="my-[5px]" />
        <div className="flex w-full justify-center">
          <TooltipWrapper content={t('calculate_button_tooltip')} id={'start_cloud_calculate'}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="danger"
              fill="outlined"
              label={t('start_cloud_calculate')}
            />
          </TooltipWrapper>
        </div>
      </Box>
    </form>
  );
};
