import React, { FC } from 'react';
import { v4 } from 'uuid';

type NumberSelectPropsType = {
  range: number;
  activeNumber?: number;
  onChange(number: number): void;
};

const NumberSelect: FC<NumberSelectPropsType> = (props) => {
  const { range, activeNumber, onChange } = props;

  function handleClick(number: number) {
    onChange(number);
  }

  return (
    <div className="w-full flex items-center justify-around bg-basic_app_bg rounded-[10px] p-[3px] shadow-md">
      {Array(range)
        .fill('')
        .map((_, index) => (
          <button
            key={v4() + index}
            onClick={() => handleClick(index)}
            className={`p-[2px_6px] ${
              activeNumber === index ? 'bg-white' : ''
            } rounded-[6px] hover:bg-white`}
          >
            {index}
          </button>
        ))}
    </div>
  );
};

export default NumberSelect;
