// File: src/layouts/layout-switcher.tsx
import React, { FC } from 'react';
import LayoutOutlet from '@app/layouts/layout';
import { useLocation } from 'react-router-dom';
import * as Layouts from '@app/layouts';

type LayoutSwitcherProps = { switchOffLayout: { [path: string]: string } };

const LayoutSwitcher: FC<LayoutSwitcherProps> = ({ switchOffLayout }) => {
  const location = useLocation();
  const currentLocationKey = location.pathname.split('/')[1]; // Извлекаем первый сегмент пути

  if (switchOffLayout && Object.keys(switchOffLayout).includes(currentLocationKey)) {
    const CurrentLayout = (Layouts as any)[switchOffLayout[currentLocationKey]];
    return <CurrentLayout />;
  }

  return <LayoutOutlet />;
};

export default LayoutSwitcher;
